import * as React from 'react';
import { LocalStorage as KmxFinanceLocalStorage } from '@kmx/online-finance-helpers';
import FinanceCalculator, { FinancingUpdateTypes, IFinanceCalculatorInitialValues } from './FinanceCalculator';
import * as formatter from '../../../utilities/formatter';
import { Store } from '../../../types/store';
import { vehicleTypeListLocalStorage } from './utils/constants';
import { getTaxesAndFeesList, ITaxesAndFees } from '../../../api/taxes-and-fees-api';
import BudgetSearch from './budget-search/BudgetSearch';
import * as financeCalculatorUtils from './utils/financeCalculatorUtils';
import { IFinanceCalculatorLocalStorage } from './utils/types';
import {
    persistFinanceCalculatorAndUpdateAnalytics,
    persistVehicleTypePreferences,
} from './utils/financeCalculatorLocalStorage';
import PreapprovedBudget from './preapproved-budget/PreapprovedBudget';
import * as budgetCalculatorUtils from './utils/budgetCalculatorUtils';
import { useInit } from '../../../utilities/hooks';
import { FBSContext } from '../../../context';
import { viewedVehiclesLocalStorage } from '../utilities/constants';
import { IViewedVehicleLocalStorage } from '../utilities/types';
import { buildVehicleTilesListFromVehicleList } from '../../../utilities/vehicleTileBuilder';
import { Vehicle } from '../../../api/types';

let cachedTaxesAndFees: ITaxesAndFees[] | null[];

interface IBudgetCalculatorProps {
    fullStoryPageId: string;
    financeCalculatorName: string;
    creditScores: budgetCalculatorUtils.ICreditScores;
}

interface IBudgetCalculatorState {
    completedBudgetCalculatorForm: {
        hasSubmittedForm: boolean;
        hasKmxFinanceInteraction: boolean;
        submittedBudgetCalculatorHref: string;
    };
    financeCalculatorSettings: {
        budgetSearchId: number;
        taxesAndFeesList: ITaxesAndFees[] | null[];
    };
    vehicleTypePreferences: {
        id: number;
        vehicleTypeList: string[];
    };
    noNearestStore: boolean;
}

let financeCalculator: FinanceCalculator;
let financeCalculatorDefaults: IFinanceCalculatorInitialValues;
let cachedFinanceCalculator: IFinanceCalculatorLocalStorage | null;
let cachedVehicleTypeList: string[];
let viewedVehiclesList: Vehicle[];

const BudgetCalculator = (props: IBudgetCalculatorProps) => {
    const fbsContext = React.useContext(FBSContext);

    useInit(() => {
        if (props.creditScores) {
            financeCalculatorDefaults = financeCalculatorUtils.buildFinanceCalculatorDefaults(
                props.financeCalculatorName,
                props.creditScores.allIds[2]
            );
        }

        cachedVehicleTypeList = [];
        const serializedVehicleTypeList = localStorage.getItem(vehicleTypeListLocalStorage.key);
        if (serializedVehicleTypeList) {
            cachedVehicleTypeList = JSON.parse(serializedVehicleTypeList);
        }

        cachedFinanceCalculator = KmxFinanceLocalStorage.getFinanceInfoFromStore();
        if (cachedFinanceCalculator) {
            financeCalculatorDefaults = financeCalculatorUtils.mapCachedFinanceCalculatorToInitialValues(
                cachedFinanceCalculator,
                financeCalculatorDefaults,
                props.creditScores
            );
        }

        financeCalculator = new FinanceCalculator(financeCalculatorDefaults);

        if (cachedFinanceCalculator) {
            persistFinanceCalculatorAndUpdateAnalytics(
                financeCalculator,
                financeCalculatorDefaults.taxesAndFeesList,
                cachedFinanceCalculator.hasInteraction,
                props.creditScores
            );
        }
    });

    const [completedBudgetCalculatorForm, setCompletedBudgetCalculatorForm] = React.useState<
        IBudgetCalculatorState['completedBudgetCalculatorForm']
    >({
        hasSubmittedForm: false,
        hasKmxFinanceInteraction: cachedFinanceCalculator ? cachedFinanceCalculator.hasInteraction : false,
        submittedBudgetCalculatorHref: '',
    });
    const [financeCalculatorSettings, setFinanceCalculatorSettings] = React.useState<
        IBudgetCalculatorState['financeCalculatorSettings']
    >({
        budgetSearchId: financeCalculator.id,
        taxesAndFeesList: financeCalculatorDefaults.taxesAndFeesList,
    });
    const [vehicleTypePreferences, setVehicleTypePreferences] = React.useState<
        IBudgetCalculatorState['vehicleTypePreferences']
    >({
        id: 0,
        vehicleTypeList: cachedVehicleTypeList,
    });
    const [noNearestStore, setNoNearestStore] = React.useState<IBudgetCalculatorState['noNearestStore']>(false);

    const subscribeToNearestStoreUpdates = (): void => {
        const event = new CustomEvent('kmxSubscribeToMyStoreModuleUpdates', {
            detail: {
                done: (store: Store) => {
                    if (store && store.State) {
                        handleNearestStoreStateChange(store.State);
                    } else {
                        console.log('BudgetCalculator: No nearest store');

                        setNoNearestStore(true);
                    }
                },
            },
        });
        document.documentElement.dispatchEvent(event);
    };
    cachedTaxesAndFees = financeCalculatorSettings.taxesAndFeesList;

    const buildVehicleTileList = () => {
        const serializedViewedVehiclesLocalStorage = localStorage.getItem(viewedVehiclesLocalStorage.key) as string;
        if (serializedViewedVehiclesLocalStorage) {
            const viewedVehicles: IViewedVehicleLocalStorage[] = JSON.parse(serializedViewedVehiclesLocalStorage);
            const viewedVehiclesList = viewedVehicles.map((vehicle: IViewedVehicleLocalStorage) => {
                return { stockNumber: vehicle.stockNumber } as Vehicle;
            });
            fbsContext.setVehicleList(buildVehicleTilesListFromVehicleList(viewedVehiclesList));
        }
    };

    const handleInputChange = (
        value: string,
        type: FinancingUpdateTypes.MonthlyPayment | FinancingUpdateTypes.DownPayment | FinancingUpdateTypes.InterestRate
    ): void => {
        if (cachedTaxesAndFees.length > 0) {
            const budgetSearchId = financeCalculator.update(cachedTaxesAndFees as ITaxesAndFees[], {
                type: type,
                value: formatter.extractNumberFromString(value),
            });

            persistFinanceCalculatorAndUpdateAnalytics(
                financeCalculator,
                cachedTaxesAndFees,
                completedBudgetCalculatorForm.hasKmxFinanceInteraction,
                props.creditScores
            );

            setFinanceCalculatorSettings({
                budgetSearchId: budgetSearchId,
                taxesAndFeesList: cachedTaxesAndFees,
            });
        }
    };

    const handleSubmit = (event: any, href: string) => {
        event.preventDefault();

        setCompletedBudgetCalculatorForm({
            hasSubmittedForm: true,
            hasKmxFinanceInteraction: true,
            submittedBudgetCalculatorHref: href,
        });
    };

    const handleNearestStoreStateChange = (nearestStoreState: string): void => {
        const nearestStoreStateHasChanged =
            nearestStoreState && nearestStoreState !== financeCalculator.nearestStoreState;

        if (nearestStoreStateHasChanged) {
            fetchtaxesAndFeesList(nearestStoreState, (taxesAndFeesList) => {
                if (taxesAndFeesList.length > 0) {
                    const budgetSearchId = financeCalculator.update(taxesAndFeesList as ITaxesAndFees[], {
                        type: FinancingUpdateTypes.NearestStoreState,
                        value: nearestStoreState,
                    });
                    persistFinanceCalculatorAndUpdateAnalytics(
                        financeCalculator,
                        financeCalculatorSettings.taxesAndFeesList,
                        completedBudgetCalculatorForm.hasKmxFinanceInteraction,
                        props.creditScores
                    );

                    setFinanceCalculatorSettings({
                        budgetSearchId: budgetSearchId,
                        taxesAndFeesList: taxesAndFeesList as ITaxesAndFees[],
                    });
                }
            });
        }
    };

    const fetchtaxesAndFeesList = (
        nearestStoreState: string,
        done: (taxesAndFeesList: ITaxesAndFees[] | null[]) => any
    ): void => {
        const handleNoTaxesAndFeesList = (err: any): void => {
            budgetCalculatorUtils.hideBudgetSearch();
            console.error('BudgetCalculator: Failed to find taxes and fees list: ', err);
        };

        if (nearestStoreState) {
            getTaxesAndFeesList(nearestStoreState)
                .then((taxesAndFeesList) => {
                    if (taxesAndFeesList && taxesAndFeesList.length > 0) {
                        done(taxesAndFeesList);
                    } else {
                        handleNoTaxesAndFeesList(taxesAndFeesList);
                    }
                })
                .catch((err: any) => {
                    handleNoTaxesAndFeesList(err);
                });
        } else {
            console.log('BudgetCalculator: No nearest store set');
        }
    };

    React.useEffect(() => {
        subscribeToNearestStoreUpdates();
        buildVehicleTileList();
    }, []);

    React.useEffect(() => {
        persistVehicleTypePreferences(vehicleTypePreferences.vehicleTypeList);
    }, [vehicleTypePreferences.id]);

    React.useEffect(() => {
        if (completedBudgetCalculatorForm.hasSubmittedForm) {
            persistFinanceCalculatorAndUpdateAnalytics(
                financeCalculator,
                financeCalculatorSettings.taxesAndFeesList,
                completedBudgetCalculatorForm.hasKmxFinanceInteraction,
                props.creditScores
            );
            window.location.href = completedBudgetCalculatorForm.submittedBudgetCalculatorHref;
        }
    }, [completedBudgetCalculatorForm.hasSubmittedForm]);

    if (noNearestStore) {
        budgetCalculatorUtils.hideBudgetSearch();
        return null;
    }

    return fbsContext.stationUrl && fbsContext.enableFBS ? (
        <PreapprovedBudget stationUrl={fbsContext.stationUrl} />
    ) : (
        <BudgetSearch
            budgetSearchId={financeCalculatorSettings.budgetSearchId}
            financeCalculator={financeCalculator}
            onInputChange={handleInputChange}
            onSubmit={handleSubmit}
            vehicleTypeList={vehicleTypePreferences.vehicleTypeList}
            onVehicleTypeListChange={(updatedVehicleTypeList) =>
                setVehicleTypePreferences({ id: ++vehicleTypePreferences.id, vehicleTypeList: updatedVehicleTypeList })
            }
            creditScores={props.creditScores}
        />
    );
};

export default BudgetCalculator;
