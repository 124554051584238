import { swipeAngle } from '../constants';

class TouchController {
    private startPosition: { x: number; y: number };
    constructor() {
        this.startPosition = { x: 0, y: 0 };
    }

    public start = (event: TouchEvent) => {
        const { clientX, clientY } = event.touches[0];
        this.startPosition.x = clientX;
        this.startPosition.y = clientY;
    };

    public move = (event: TouchEvent) => {
        const { clientX, clientY } = event.touches[0];
        const currentX = clientX;
        const currentY = clientY;
        const degree = this.toDegree(currentY - this.startPosition.y, currentX - this.startPosition.x);
        const direction = this.getTouchDirection(degree);
        if (direction === 'horizontal' && event.cancelable) {
            event.preventDefault();
        }
    };

    private toDegree(y: number, x: number): number {
        return Math.atan2(y, x) * (180 / Math.PI);
    }

    private getTouchDirection(angle: number): string {
        const gap = Math.abs(90 - Math.abs(angle));

        if (gap <= swipeAngle) {
            return 'vertical';
        }

        return 'horizontal';
    }
}

export default TouchController;
