import * as React from 'react';
import classNames from 'classnames';
import { getTabPanelId } from './TabPanel';

interface ITab {
    className?: string;
    active: boolean;
    disabled?: boolean;
    id: string;
    inputProps?: any;
    children: any;
    index?: number;
    numTabs: number;
    onClick: Function;
    onBlur?: Function;
    onFocus?: Function;
}

function Tab(props: ITab) {
    const {
        className,
        active,
        disabled = false,
        id,
        inputProps,
        onBlur,
        onClick,
        onFocus,
        children,
        index,
        numTabs
    } = props;

    const buttonId = `button-${id}`;

    return (
        <button
            className={classNames(className, 'tab-container', {
                active: active,
                first: index === 0,
                last: index === numTabs - 1
            })}
            id={buttonId}
            role="tab"
            aria-selected={active ? 'true' : 'false'}
            aria-controls={getTabPanelId(id)}
            disabled={disabled}
            onClick={onClick}
            onBlur={onBlur}
            onFocus={onFocus}
            {...inputProps}
        >
            <label aria-labelledby={buttonId}>{children}</label>
        </button>
    );
}

export default Tab;
