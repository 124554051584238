import * as React from 'react';

interface IErrorBoundaryProps {}

interface IErrorBoundaryState {
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    render() {
        return this.state.hasError || React.Children.count(this.props.children) === 0 ? null : this.props.children;
    }
}
