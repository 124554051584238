import { MAX_BEST_MATCH_VEHICLE_TILES } from './constants';
import { getRecommendedVehicleTiles, getBestMatchVehicleTiles } from '../../../utilities/vehicleTileBuilder';
import { getStoreId, getVisitorId } from '../../../utilities/visitor-cookie';
import { IVehicleTile } from '../../../types/vehicleTile';
import { getLatitude, getLongitude } from '../../../utilities/cookieHelper';

export const selectSourceStockNumbers = (favoritedStockNumberList?: number[], viewedVehicleList?: IVehicleTile[]) => {
    if (favoritedStockNumberList && favoritedStockNumberList.length > 0) {
        return favoritedStockNumberList.map((stockNumber) => String(stockNumber));
    } else if (viewedVehicleList && viewedVehicleList.length > 0) {
        return viewedVehicleList.map((vehicleTile) => String(vehicleTile.stockNumber));
    } else {
        return [];
    }
};

export const fetchAndBuildRecommendedVehicleTiles = (
    cb: (recommendedVehicleTileList: IVehicleTile[]) => any,
    favoritedStockNumberList?: number[],
    viewedVehicleList?: IVehicleTile[]
) => {
    const sourceStockNumbers = selectSourceStockNumbers(favoritedStockNumberList, viewedVehicleList);
    getRecommendedVehicleTiles(sourceStockNumbers, getLatitude(), getLongitude(), getVisitorId()).then(
        (recommendedVehicleList: IVehicleTile[]) => {
            cb(recommendedVehicleList);
        }
    );
};
