import { formatVin } from './format';

export const isValidVin = (value: any) => {
    if (!value || !value.length || value.length != 17) return false;
    value = formatVin(value);
    return getCheckDigit(value) == value.charAt(8);
};

const getCheckDigit = (vin: any) => {
    // Override for 2015 Jeep Renegade and 2016 Fiat 500X that have check digit problems
    if (vin.substr(0, 5) === 'ZACCJ' || vin.substr(0, 5) === 'ZFBCF') {
        return vin.charAt(8);
    }

    const map = '0123456789X';
    const weights = '8765432X098765432';
    let sum = 0;
    for (let i = 0; i < 17; ++i) {
        sum += transliterate(vin.charAt(i)) * map.indexOf(weights.charAt(i));
    }
    return map.charAt(sum % 11);
};

const transliterate = (vinChar: any) => {
    return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(vinChar) % 10;
};
