import * as budgetCalculatorUtils from './budgetCalculatorUtils';
import { IBudgetSearchContent } from './types';

export const budgetSearch: IBudgetSearchContent = {
    header: 'See cars your budget will love',
    vehicleTypeCarouselSelect: {
        header: 'What kinds of cars do you want?',
        headerDetails: 'Pick all that apply',
        vehicleTypeTileImageWidth: '78',
        vehicleTypeTileImageHeight: '43',
        vehicleTypeList: [
            {
                pathSegment: 'sport-utilities',
                imgSrc: '/home/images/shared/icons/vehicle-types/sport-utilities.svg',
                label: 'SUVs'
            },
            {
                pathSegment: 'pickup-trucks',
                imgSrc: '/home/images/shared/icons/vehicle-types/pickup-trucks.svg',
                label: 'Trucks'
            },
            {
                pathSegment: 'sedans',
                imgSrc: '/home/images/shared/icons/vehicle-types/sedans.svg',
                label: 'Sedans'
            },
            {
                pathSegment: 'crossovers',
                imgSrc: '/home/images/shared/icons/vehicle-types/crossovers.svg',
                label: 'Crossovers'
            },
            {
                pathSegment: 'coupes',
                imgSrc: '/home/images/shared/icons/vehicle-types/coupes.svg',
                label: 'Coupes'
            },
            {
                pathSegment: 'convertibles',
                imgSrc: '/home/images/shared/icons/vehicle-types/convertibles.svg',
                label: 'Convertibles'
            },
            {
                pathSegment: 'luxury-vehicles',
                imgSrc: '/home/images/shared/icons/vehicle-types/luxury-vehicles.svg',
                label: 'Luxury'
            },
            {
                pathSegment: 'sports-cars',
                imgSrc: '/home/images/shared/icons/vehicle-types/sports-cars.svg',
                label: 'Sports Cars'
            },
            {
                pathSegment: 'diesel-engines',
                imgSrc: '/home/images/shared/icons/vehicle-types/diesel-engines.svg',
                label: 'Diesel'
            },
            {
                pathSegment: 'minivans-and-vans',
                imgSrc: '/home/images/shared/icons/vehicle-types/minivans-and-vans.svg',
                label: 'Vans'
            },
            {
                pathSegment: 'hybrids',
                imgSrc: '/home/images/shared/icons/vehicle-types/hybrids.svg',
                label: 'Hybrids'
            },
            {
                pathSegment: 'wagons',
                imgSrc: '/home/images/shared/icons/vehicle-types/wagons.svg',
                label: 'Wagons'
            },
            {
                pathSegment: 'electric',
                imgSrc: '/home/images/shared/icons/vehicle-types/electric-cars.svg',
                label: 'Electric'
            }
        ]
    },
    monthlyPaymentInput: {
        label: 'Monthly Payment'
    },
    downPaymentInput: {
        label: 'Down Payment',
        zeroDownPaymentPrompt: 'Any amount can help'
    },
    creditScoreSelect: {
        label: 'Credit Score'
    },
    vehiclePriceHeader: 'Est. Vehicle Price',
    vehiclePriceAriaLabel: 'Your budget is now around',
    vehiclePriceDisclaimerButtonText: 'How we calculated this',
    primaryCtaId: 'calc-button-shop-cars',
    primaryCtaText: 'Get Pre-Qualified',
    buildPrimaryCtaLink: options =>
        `/pre-qual/app?op=homepagecalc&vehicleprice=${Math.round(options.upperRange)}&downpayment=${
            options.downPayment
        }&rate=${options.interestRate}`,
    secondaryCtaText: 'See all cars'
};

export const budgetSearchDialog = {
    header: 'How we calculated this',
    buildDisclaimer: (loanTerm: number) =>
        `Tax, title, and tags vary by state and are calculated at time of purchase. Estimated values are for illustration purposes only, do not constitute an advertisement or offer of specific credit terms; and are based, where applicable, on the information you enter. APRs and terms used in estimates – including a ${loanTerm} month term – may be unavailable based on vehicle, state of purchase, or your credit profile. Actual terms are subject to credit approval and availability.`,
    closeButtonText: 'Got it'
};

export const buildCreditScoreSelectOptions = (
    creditScores: budgetCalculatorUtils.ICreditScores
): { label: string; value: string }[] => {
    const creditScoreSelectOptions: { label: string; value: string }[] = [];

    creditScores.allIds.forEach(id => {
        creditScoreSelectOptions.push({
            label: creditScores.byId[id].label,
            value: creditScores.byId[id].value
        });
    });

    return creditScoreSelectOptions;
};
