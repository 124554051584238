import { useEffect, useRef, ReactNode } from 'react';
import { useIntersectionObserver } from '../../utilities/hooks';
import './CoverSection.scss';

type Props = {
    bgImageUrl: string;
    children: ReactNode;
};

const CoverSection = ({ bgImageUrl, children }: Props) => {
    const coverSectionRef = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(coverSectionRef, 0);

    useEffect(() => {
        if (isIntersecting) {
            coverSectionRef.current?.classList.add('cover-section--active');
        }
    }, [isIntersecting]);

    useEffect(() => {
        if (coverSectionRef.current) {
            coverSectionRef.current.style.backgroundImage = `url(${bgImageUrl})`;
        }
    }, [bgImageUrl]);

    return (
        <div ref={coverSectionRef} className="cover-section">
            <div className="cover-section--children-container">{children}</div>
        </div>
    );
};

export default CoverSection;
