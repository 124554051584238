import * as React from 'react';
import classNames from 'classnames';

interface IRecentSearchTileHeaderProps {
    title: string;
    inventoryCount: number;
    inventoryCountText: string;
    newMatchCountText?: string;
    isPartialRecentSearch?: boolean;
}

const RecentSearchTileHeader = (props: IRecentSearchTileHeaderProps) => {
    const { title, newMatchCountText, inventoryCount, inventoryCountText, isPartialRecentSearch } = props;
    return (
        <figcaption className="kmx-recent-search-tile-caption">
            <h2 className="kmx-recent-search-tile-title">{title}</h2>
            <span className="kmx-recent-search-tile-inventory-count-row">
                {newMatchCountText ? (
                    <span className="kmx-recent-search-tile-new-matches-badge" aria-label={`${newMatchCountText} new matches.`}>{newMatchCountText}</span>
                ) : null}
                <span
                    className={classNames('kmx-recent-search-tile-search-count', {
                        'kmx-recent-search-tile-search-count-zero': inventoryCount === 0 && !isPartialRecentSearch
                    })}
                >
                    {inventoryCountText}
                </span>
            </span>
        </figcaption>
    );
};

export default RecentSearchTileHeader;
