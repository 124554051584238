import { AddEventHandlers } from './types';

const leaveSlider = ({
    tinySlider,
    nextFocus,
    prevFocus,
}: AddEventHandlers): ((direction: string) => void) => direction => {
    const preCarousel = document.querySelector(prevFocus) as HTMLElement | null;
    const postCarousel = document.querySelector(nextFocus) as HTMLElement | null;
    if (direction === 'back' && preCarousel) {
        preCarousel.focus();
        tinySlider.play();
    } else if (direction === 'forward' && postCarousel) {
        postCarousel.focus();
        tinySlider.play();
    }
};

export default leaveSlider;
