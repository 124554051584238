import * as React from 'react';
import { getStatePricingDisclaimer } from '../api/disclaimers-api';

interface IStatePricingDisclaimerProps {
    fullStoryPageId: string;
}

class StatePricingDisclaimer extends React.Component<IStatePricingDisclaimerProps> {
    public state = {
        text: '',
        warrantyDisclaimerText:
            'Limited warranty is 90-Day/4,000-Miles, whichever comes first. See store for written details. We make every effort to provide accurate information, but please verify before purchasing.',
    };

    public componentDidMount(): void {
        this.loadStatePricingDisclaimer();
    }

    public loadStatePricingDisclaimer = (): void => {
        getStatePricingDisclaimer().then((text: string) => this.setState({ text }));
    };

    public render(): React.ReactNode {
        const { warrantyDisclaimerText, text } = this.state;

        if (text.length === 0) {
            return null;
        }

        return (
            <div
                data-fs-section={`${this.props.fullStoryPageId}: Pricing Disclaimer`}
                className="state-pricing-disclaimer--max-width"
            >
                <p className="state-pricing-disclaimer--copy">
                    * {text} {warrantyDisclaimerText}
                </p>
            </div>
        );
    }
}

export default StatePricingDisclaimer;
