import { useEffect, useRef } from 'react';
import Icons from '../../utilities/icons';
import './dialog.scss';

export interface IDialogProps {
    children: React.ReactChild;
    isVisible: boolean;
    onClose: () => void;
}

export const Dialog = (props: IDialogProps) => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const { children, isVisible, onClose } = props;

    // animates-out the dialog before the native dialog api removes it from the DOM
    const handleClose = (dialog: HTMLDialogElement) => {
        const fadeOutCSSClass = `hp-dialog--fade-out`;
        dialog.classList.add(fadeOutCSSClass);
        dialog.addEventListener('transitionend', () => {
            dialog.close();
            dialog.classList.remove(fadeOutCSSClass);
        });
    };

    useEffect(() => {
        const dialog = dialogRef.current;

        // watch for change in visibility from parent.
        const closeIfBackgroundClicked = (e: MouseEvent, dialog: HTMLDialogElement) => {
            const rect = dialog.getBoundingClientRect();
            const isInDialog =
                rect.top <= e.clientY &&
                e.clientY <= rect.top + rect.height &&
                rect.left <= e.clientX &&
                e.clientX <= rect.left + rect.width;
            if (!isInDialog) {
                handleClose(dialog);
            }
        };

        dialog?.querySelector('.hp-dialog__btn-close')?.addEventListener('click', () => {
            handleClose(dialog);
        });

        // catch native dialog close event and update parent's state
        dialog?.addEventListener('close', () => {
            onClose();
        });

        // catch user click on backdrop
        dialog?.addEventListener('click', (e: MouseEvent) => {
            closeIfBackgroundClicked(e, dialog);
        });

        // parent state governs visibility
        if (isVisible) {
            dialog?.showModal();
        } else {
            const dialogContentClassName = `hp-dialog__content`; // ex. kmx-dialog__content;
            const fadeOutCSSClass = `hp-dialog--fade-out`;
            dialog?.addEventListener('transitionend', () => {
                alert('trans');
                dialog.close();
                dialog.classList.remove(fadeOutCSSClass);
            });
            dialog?.querySelector(dialogContentClassName)?.classList.add(fadeOutCSSClass); // ex. kmx-dialog--fade-out;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    if (!isVisible) return <></>;

    return (
        isVisible && (
            <dialog data-testid="dialog" className={`hp-dialog`} ref={dialogRef}>
                <div className="hp-dialog__innerWrapper">
                    <div className="hp-dialog__content">{children}</div>
                    <button
                        data-testid="dialog__btn-close"
                        type="button"
                        className="hp-dialog__btn-close"
                        aria-label="Close Popover"
                    >
                        {Icons.close}
                    </button>
                </div>
            </dialog>
        )
    );
};

export default Dialog;
