import axios from 'axios';
import { SearchApiResponse, Vehicle } from './types';

export function getVehicles(params: {
    stockNumberList?: number[];
    take?: number;
    scoringProfile?: string;
    transferDestinationStoreId?: string;
    zipCode?: string;
    radiusInMiles?: number;
    storeId?: string;
}): Promise<Vehicle[]> {
    try {
        let url = '/home/api/search';
        const queryParamsArray = [];

        queryParamsArray.push('isSaleable=true');

        if (params.stockNumberList && params.stockNumberList.length > 0) {
            params.stockNumberList.map((val) => {
                queryParamsArray.push(`stockNumbers=${val}`);
            });
        }

        if (params.take) {
            queryParamsArray.push(`take=${params.take}`);
        }

        if (params.scoringProfile) {
            queryParamsArray.push(`scoringProfile=${params.scoringProfile}`);
        }

        if (params.transferDestinationStoreId) {
            queryParamsArray.push(`transferDestinationStoreId=${params.transferDestinationStoreId}`);
        }

        if (params.storeId) {
            queryParamsArray.push(`storeId=${params.storeId}`);
        }

        if (params.zipCode) {
            queryParamsArray.push(`zipcode=${params.zipCode}`);
        }

        if (params.radiusInMiles) {
            queryParamsArray.push(`radiusInMiles=${params.radiusInMiles}`);
        }

        if (queryParamsArray.length) {
            url += `?${queryParamsArray.join('&')}`;
        }

        return axios
            .get<SearchApiResponse>(url)
            .then((response) => {
                if (response && response.data && response.data.items && response.data.items.length > 0) {
                    return response.data.items;
                }
                return [];
            })
            .catch(() => []);
    } catch {
        return Promise.resolve([]);
    }
}
