export const formatSeperatedInteger = (value: any) => {
    if (value == null) {
        return null;
    } else if (typeof value === 'number') {
        return value ? value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
    } else {
        return value ? value.replace(/[^0-9]*/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
    }
};

export const formatVin = (input: any) => {
    if (input == null || input == '') return input;
    else
        return input
            .toUpperCase()
            .replace(/I/g, '1')
            .replace(/O/g, '0')
            .replace(/Q/g, '0');
};
