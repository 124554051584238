import axios from 'axios';

interface ISocialProofApiResponse {
    data: {
        data: {
            post_id: string;
            thumbnail_url: string;
            thumbnail_height: string;
            thumbnail_width: string;
            author_name: string;
            img_alt: string;
            perma_link: string;
        }[];
    };
}

export interface ISocialProofApiPost {
    postId: string;
    thumbnailUrl: string;
    thumbnailHeight: string;
    thumbnailWidth: string;
    authorName: string;
    imgAlt: string;
    permaLink: string;
}

export function buildPostIdQueryParams(ids: readonly string[]) {
    const duplicateIds = ids.slice();
    duplicateIds.sort();
    return duplicateIds.reduce(
        (acc: any, id: string, currentIndex: number) => `${acc}${currentIndex > 0 ? '&' : ''}id=${id}`,
        ''
    );
}

export function getSocialProofPosts(ids: string[]): Promise<ISocialProofApiPost[] | []> {
    try {
        const postIdQueryParams = buildPostIdQueryParams(ids);
        return axios
            .get(`/home/api/socialproof?${postIdQueryParams}`)
            .then((response: ISocialProofApiResponse) => {
                if (response.data && response.data.data) {
                    const camelCaseData: ISocialProofApiPost[] = response.data.data.map(post => {
                        return {
                            postId: post.post_id,
                            thumbnailUrl: post.thumbnail_url,
                            thumbnailHeight: post.thumbnail_height,
                            thumbnailWidth: post.thumbnail_width,
                            authorName: post.author_name,
                            imgAlt: post.img_alt,
                            permaLink: post.perma_link,
                        };
                    });
                    return camelCaseData;
                }

                return [];
            })
            .catch(() => []);
    } catch (e) {
        return Promise.resolve([]);
    }
}
