import * as React from 'react';
import MDCSelect from '../../../../components/MDCSelect';
import { budgetSearch, buildCreditScoreSelectOptions } from '../utils';
import * as constants from '../utils/constants';
import { getRateTier } from '../utils/financeCalculatorUtils';
import { IFinanceCalculator, FinancingUpdateTypes } from '../FinanceCalculator';
import NumberRoller from '../../../../components/NumberRoller';
import * as formatter from '../../../../utilities/formatter';
import Icons from '../../../../utilities/icons';
import VehicleTypeMultiselectCarousel from '../../../../components/carousels/vehicle-type-multiselect/VehicleTypeMultiselectCarousel';
import * as budgetCalculatorUtils from '../utils/budgetCalculatorUtils';
import { MAX_DOWN_PAYMENT, MAX_MONTHLY_PAYMENT } from '../utils/constants';
import Dialog from '../../../../components/Dialog';
import BudgetSearchDialogContent from './BudgetSearchDialogContent';

interface IBudgetSearchProps {
    budgetSearchId: number;
    financeCalculator: IFinanceCalculator;
    vehicleTypeList: string[];
    onInputChange: (
        value: string,
        type: FinancingUpdateTypes.MonthlyPayment | FinancingUpdateTypes.DownPayment | FinancingUpdateTypes.InterestRate
    ) => any;
    onSubmit: (event: any, href: string) => any;
    onVehicleTypeListChange: (vehicleTypeList: string[]) => any;
    creditScores: budgetCalculatorUtils.ICreditScores;
}

const BudgetSearch = (props: IBudgetSearchProps) => {
    const [budgetSearchDialogOpen, setBudgetSearchDialogOpen] = React.useState(false);

    const buildVehiclePriceLowerRange = (): number | undefined => {
        const inventorySearchPriceFloorDistanceFromMaximum = Math.round(
            (props.financeCalculator.vehiclePrice * 25) / 100
        );
        const lowerRange =
            props.financeCalculator.vehiclePrice - inventorySearchPriceFloorDistanceFromMaximum > 0
                ? props.financeCalculator.vehiclePrice - inventorySearchPriceFloorDistanceFromMaximum
                : undefined;
        return lowerRange;
    };

    const { monthlyPayment, downPayment, vehiclePrice } = props.financeCalculator;

    const primaryCtaLink = budgetSearch.buildPrimaryCtaLink({
        upperRange: vehiclePrice,
        lowerRange: buildVehiclePriceLowerRange(),
        typesPath: props.vehicleTypeList.length > 0 ? '/' + props.vehicleTypeList.join('/') : '',
        downPayment: downPayment,
        interestRate: props.financeCalculator.annualInterestRate,
    });
    const showZeroDownPaymentHelperText = downPayment === 0;
    const creditScoreSelectOptions = buildCreditScoreSelectOptions(props.creditScores);

    return (
        <div id="budget_search_container">
            <div className="budget-search-container max-width--xl">
                <div className="budget-search-header text-centered">
                    <h2 id="budget-search-heading" className="budget-search-heading">
                        <div className="budget-search-heading">
                            &nbsp; Pre-qualify with no&nbsp;
                            <span className="budget-search-heading-partial__desktop">impact to your credit</span>
                            <span className="budget-search-heading-partial__mobile">
                                <br />
                                impact to your credit
                            </span>
                        </div>
                    </h2>
                </div>
                <div id="inner-budget-container">
                    <div
                        className="budget-search-calculator-container"
                        data-clickprops={`Element type: Budget calculator,Down payment: ${downPayment},Monthly payment: ${monthlyPayment},Tier: ${getRateTier(
                            props.financeCalculator.annualInterestRate,
                            creditScoreSelectOptions
                        )},Term: ${props.financeCalculator.loanTerm}`}
                    >
                        <div className="budget-search-calculator-row">
                            <div className="budget-search-form-row budget-search-form-row--vehicle-type">
                                <h2 className="budget-search-form--vehicle-type-header">
                                    {budgetSearch.vehicleTypeCarouselSelect.header}{' '}
                                </h2>
                                <VehicleTypeMultiselectCarousel
                                    vehicleTypeList={props.vehicleTypeList}
                                    onVehicleTypeListChange={props.onVehicleTypeListChange}
                                />
                            </div>
                            <div>
                                <div className="budget-search-calculator-input-container">
                                    <div className="budget-search-calculator-input-container-mp-dp-lt-row">
                                        <div
                                            aria-describedby={`0 to ${MAX_MONTHLY_PAYMENT} dollars`}
                                            className="budget-search-calculator-input-container__input__wrapper"
                                        >
                                            <label
                                                htmlFor="budget-search-calculator__monthly-payment"
                                                className="budget-search-calculator-input-container__input-label"
                                            >
                                                {budgetSearch.monthlyPaymentInput.label}
                                            </label>
                                            <input
                                                id="budget-search-calculator__monthly-payment"
                                                className="budget-search-calculator-input-container__input"
                                                type="text"
                                                value={formatter.toDollars(monthlyPayment)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    formatter.extractNumberFromString(e.target.value) <=
                                                    constants.MAX_MONTHLY_PAYMENT
                                                        ? props.onInputChange(
                                                              e.target.value,
                                                              FinancingUpdateTypes.MonthlyPayment
                                                          )
                                                        : null
                                                }
                                                pattern={'\\d*'}
                                                onPaste={(event: React.ClipboardEvent) => event.preventDefault()}
                                            />
                                        </div>
                                        <div
                                            aria-describedby={`0 to ${MAX_DOWN_PAYMENT} dollars`}
                                            className="budget-search-calculator-input-container__input__wrapper"
                                        >
                                            <label
                                                htmlFor="budget-search-calculator__down-payment"
                                                className="budget-search-calculator-input-container__input-label"
                                            >
                                                {budgetSearch.downPaymentInput.label}
                                            </label>
                                            <input
                                                id="budget-search-calculator__down-payment"
                                                className="budget-search-calculator-input-container__input"
                                                type="text"
                                                value={formatter.toDollars(downPayment)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    formatter.extractNumberFromString(e.target.value) <=
                                                    constants.MAX_DOWN_PAYMENT
                                                        ? props.onInputChange(
                                                              e.target.value,
                                                              FinancingUpdateTypes.DownPayment
                                                          )
                                                        : null;
                                                }}
                                                pattern="\\d*"
                                                onPaste={(event: React.ClipboardEvent) => event.preventDefault()}
                                                title={
                                                    showZeroDownPaymentHelperText
                                                        ? budgetSearch.downPaymentInput.zeroDownPaymentPrompt
                                                        : undefined
                                                }
                                            />
                                            <div id="zero-downpayment-message">
                                                {showZeroDownPaymentHelperText &&
                                                    budgetSearch.downPaymentInput.zeroDownPaymentPrompt}
                                            </div>
                                        </div>
                                        <div
                                            aria-describedby={`0 to ${MAX_DOWN_PAYMENT} dollars`}
                                            className="budget-search-calculator-input-container__input__wrapper"
                                        >
                                            <MDCSelect
                                                id="credit-rating-select"
                                                label={budgetSearch.creditScoreSelect.label}
                                                value={String(props.financeCalculator.annualInterestRate)}
                                                options={creditScoreSelectOptions}
                                                onChange={(event) =>
                                                    props.onInputChange(
                                                        event.target.value,
                                                        FinancingUpdateTypes.InterestRate
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="budget-search-calculator-row">
                            <div className="budget-search-price-container ">
                                <div className="budget-search-price-header">
                                    <h3 className="budget-search-price-heading">{budgetSearch.vehiclePriceHeader}</h3>
                                    <button
                                        className="budget-search-disclaimer-dialog-button"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            setBudgetSearchDialogOpen(true);
                                        }}
                                    >
                                        <span className="visually-hidden">
                                            budget calculator details. content opens in popup.
                                        </span>
                                        {Icons.info_rebrand}
                                    </button>
                                </div>
                                <div className="budget-search-price-section">
                                    <div className="budget-search-price">
                                        <NumberRoller
                                            formatter={formatter.toDollars}
                                            value={vehiclePrice < 0 ? 0 : vehiclePrice}
                                            ariaLiveLabel={budgetSearch.vehiclePriceAriaLabel}
                                        />
                                    </div>
                                    <hzn-button
                                        full-width
                                        id={`${budgetSearch.primaryCtaId}_tablet-mobile`}
                                        class="budget-search-price-shop-button"
                                        color="primary"
                                        href={primaryCtaLink}
                                        data-finance-lead-initiate="true"
                                        data-finance-op="HP_Finance1stModule"
                                        data-finance-formtype="Finance First Prequalification Application"
                                        data-finance-action="Init"
                                        data-kmx-analytics="noClickTrack"
                                        onClick={(e: any) => {
                                            props.onSubmit(e, primaryCtaLink);
                                        }}
                                    >
                                        {budgetSearch.primaryCtaText}
                                    </hzn-button>
                                </div>
                                <Dialog
                                    isVisible={budgetSearchDialogOpen}
                                    onClose={() => setBudgetSearchDialogOpen(false)}
                                >
                                    <BudgetSearchDialogContent financeCalculator={props.financeCalculator} />
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BudgetSearch;
