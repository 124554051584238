import * as React from 'react';
import { IInstantOfferDetails } from './InstantOffers';
import { buildScheduleIneligibleOfferLink, makeModelMaxLength } from './utils/constants';

export interface IIneligibleOfferProps {
    instantOfferDetails: IInstantOfferDetails;
}

function prepareVehicleTitle(make: string | undefined, model: string | undefined) {
    const vehicleTitle = make + ' ' + model;

    if (make && model && vehicleTitle.length <= makeModelMaxLength) {
        return vehicleTitle;
    } else if (make && make.length <= makeModelMaxLength) {
        return make;
    } else {
        return 'car';
    }
}

function IneligibleOffer(props: IIneligibleOfferProps) {
    const vehicleTitle = prepareVehicleTitle(
        props.instantOfferDetails.vehicleMake,
        props.instantOfferDetails.vehicleModel
    );

    return (
        <div id="icoIneligible" className="ico-ineligible">
            <div>
                <img
                    width="368"
                    height="218"
                    alt="Vehicle condition verification"
                    className="ico-ineligible-img"
                    src="/home/images/home/instant-offers/condition-verification-graphic.png"
                />
                <h4 className="ico-ineligible-title ">We&apos;d like to see your {vehicleTitle} in&nbsp;person</h4>
                <p className="ico-ineligible-body">
                    Online offers are based on recent appraisals of similar cars, and we haven&apos;t seen many like
                    yours lately. For your offer, stop by your local CarMax.
                </p>
            </div>
            <div className="ico-ineligible-button-container">
                <hzn-button
                    variant="cta"
                    href={buildScheduleIneligibleOfferLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="ico-ineligible-schedule-btn"
                    full-width
                >
                    Schedule in-store appraisal
                </hzn-button>
                <button
                    className="ico-ineligible-change-vehicle-btn"
                    onClick={() => props.instantOfferDetails.onStartNewOffer()}
                >
                    Change vehicle
                </button>
            </div>
        </div>
    );
}

export default IneligibleOffer;
