import React, { useEffect, useState } from 'react';

const useFocus = (ref: React.RefObject<HTMLElement | null>, defaultFocus = false) => {
    const [isFocused, setFocus] = useState(defaultFocus);

    useEffect(() => {
        const current = ref.current;
        const onBlur = () => setFocus(false);
        const onFocus = () => setFocus(true);

        if (current) {
            current.addEventListener('blur', onBlur);
            current.addEventListener('focus', onFocus);
        }

        return () => {
            if (current) {
                current.removeEventListener('blur', onBlur);
                current.removeEventListener('focus', onFocus);
            }
        };
    });

    return isFocused;
};

export default useFocus;
