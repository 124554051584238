import * as React from 'react';
import classNames from 'classnames';

interface IEligibleOfferCountdownProps {
    title: string;
    value: number;
}

function EligibleOfferCountdown(props: IEligibleOfferCountdownProps) {
    const firstDigit = props != null && props.value >= 10 ? Number(props.value.toString().charAt(0)) : 0;
    const secondDigit =
        props != null && props.value >= 10
            ? Number(props.value.toString().charAt(1))
            : Number(props.value.toString().charAt(0));

    return (
        <span className="instant-offers-eligible-offer--countdown-container">
            <div>
                <span className="instant-offers-eligible-offer--countdown-digit">{firstDigit}</span>
                <span className="instant-offers-eligible-offer--countdown-digit">{secondDigit}</span>
            </div>
            <div className="instant-offers-eligible-offer--countdown-label">{props.title}</div>
        </span>
    );
}

export default EligibleOfferCountdown;
