import { IFinanceCalculator } from '../FinanceCalculator';
import * as budgetCalculatorUtils from './budgetCalculatorUtils';

export interface IFinanceCalculatorDigitalData {
    calculatorName: string;
    creditRating: string;
    downPayment: string;
    interestRate: string;
    monthlyPayment: string;
    selectedFeeState: string;
    termLength: string;
    vehiclePrice: string;
}

export const updateDigitalData = (
    window: Window,
    financeCalculator: IFinanceCalculator,
    creditScores: budgetCalculatorUtils.ICreditScores
) => {
    if (window.digitalData) {
        const financeCalculatorDigitalData: IFinanceCalculatorDigitalData = {
            calculatorName: financeCalculator.name,
            creditRating: creditScores.byId[financeCalculator.annualInterestRate].name,
            downPayment: String(financeCalculator.downPayment),
            interestRate: String(financeCalculator.annualInterestRate),
            monthlyPayment: String(financeCalculator.monthlyPayment),
            selectedFeeState: financeCalculator.nearestStoreState,
            termLength: String(financeCalculator.loanTerm),
            vehiclePrice: String(financeCalculator.vehiclePrice)
        };

        window.digitalData.financeCalculator = financeCalculatorDigitalData;
    }
};
