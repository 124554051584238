import * as React from 'react';
import classNames from 'classnames';

interface ILocalCarsSkeletonVehicleTileProps {
    isLoading: boolean;
    children?: React.ReactChildren;
}

export const LocalCarsSkeletonVehicleTile = (props: ILocalCarsSkeletonVehicleTileProps) => {
    return (
        <div className={classNames('skeleton-vehicle-tile', { loading: props.isLoading })}>
            <div className="header"></div>
            <div className="body">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
