export const loadInstantOffersComponentAssets = (scriptLoadedCallback: () => any, styleLoadedCallback: () => any) => {
    const instantOffersCSSDataEl = document.querySelector('[data-appraisals-css-src]') as any;
    const instantOffersJSDataEl = document.querySelector('[data-appraisals-js-src]') as any;
    const instantOffersCSSSrc = instantOffersCSSDataEl.getAttribute('data-appraisals-css-src');
    const instantOffersJSSrc = instantOffersJSDataEl.getAttribute('data-appraisals-js-src');

    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.setAttribute('type', 'text/css');
    linkEl.setAttribute('href', instantOffersCSSSrc);

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('type', 'text/javascript');
    scriptEl.setAttribute('src', instantOffersJSSrc);

    document.getElementsByTagName('head')[0].appendChild(linkEl);
    document.getElementsByTagName('head')[0].appendChild(scriptEl);

    if (scriptLoadedCallback && typeof scriptLoadedCallback === 'function') {
        scriptEl.onload = () => scriptLoadedCallback();
    }

    if (styleLoadedCallback && typeof styleLoadedCallback === 'function') {
        linkEl.onload = () => styleLoadedCallback();
    }
};
