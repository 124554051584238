import { BLOCKS } from '@contentful/rich-text-types';
import axios from 'axios';
import { ContentfulPageResponse, ContentResponse, IContentfulLinkFarmResponse } from './types/content-api';

export const emptyContentResponse: ContentResponse = {
    allPosts: [],
    portraitLeft: [],
    portraitRight: [],
    landscapeLeft: [],
    landscapeTop: [],
    landscapeBottom: [],
};

export const emptyContentfulPageResponse: ContentfulPageResponse = {
    pageContent: { nodeType: BLOCKS.DOCUMENT, value: 'string', data: {}, content: [], marks: [] },
    pageName: '',
};

export const emptyContentfulLinkFarmResponse: IContentfulLinkFarmResponse = {
    tabItems: [],
};

export const VENDOR_ENTRY_ID = '3AjnUxJHvcDLloMJrd9S57';
export const ESIGN_DISCLOSURE_ENTRY_ID = '6DRCwMIskHDvBTNFb5LY9F';
export const INTEREST_BASED_ADS_ENTRY_ID = '5B3g6V3uq5Ey2DtKsBEYXR';
export const TERMS_ENTRY_ID = '63qdtxuRpsn7BM7GAr5gg2';
export const NOTICE_OF_ACCESSIBILITY_ENTRY_ID = '1WBZGJXw3frXP3jDcQrx7X';
export const CAF_CONSUMER_DISCLOSURE_ENTRY_ID = '2rY1jUTSAt6lZvMGsFySkd';
export const RESPONSIBLE_DISCLOSURE_ENTRY_ID = '2zPrnnf7pUCMt3gjeItnjh';
export const TEXTING_TERMS_ID = '70wUNefD1CICMz2386SitB';
export const LINK_FARM_ID = '3KVahwtGapk95QovNLirRM';

export function getSocialProofCarousel(): Promise<ContentResponse> {
    try {
        return axios
            .get<ContentResponse>(`/home/api/content/social-proof-carousel`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
                return Promise.resolve(emptyContentResponse);
            })
            .catch(() => emptyContentResponse);
    } catch (e) {
        return Promise.resolve(emptyContentResponse);
    }
}

export function getContentfulPage(entryId: string): Promise<ContentfulPageResponse> {
    try {
        return axios
            .get<ContentfulPageResponse>(`/home/api/content/contentful-page/${entryId}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
                return Promise.resolve(emptyContentfulPageResponse);
            })
            .catch(() => emptyContentfulPageResponse);
    } catch (e) {
        return Promise.resolve(emptyContentfulPageResponse);
    }
}

export function getContentfulLinkFarm(): Promise<IContentfulLinkFarmResponse> {
    try {
        return fetch(`/home/api/content/link-farm`)
            .then((response: Response) => {
                return Promise.resolve(response.json());
            })
            .catch(() => emptyContentfulLinkFarmResponse);
    } catch (e) {
        return Promise.resolve(emptyContentfulLinkFarmResponse);
    }
}
