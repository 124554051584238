import { IRecentSearch, IRecentSearchApiResponse } from './types';
import { getVisitorId } from '../utilities/visitor-cookie';
import { getMyKmxUserId } from '../utilities/cookieHelper';

export function getRecentSearches(): Promise<IRecentSearch[]> {
    const visitorId = getVisitorId();
    const userId = getMyKmxUserId();
    try {
        if (visitorId) {
            const url = `/home/api/recentsearches?visitorId=${visitorId}&userId=${userId}`;
            return fetch(url)
                .then((response) => response.json())
                .then((data: IRecentSearchApiResponse) => {
                    if (data && data.items && data.items.length > 0) {
                        return data.items;
                    }
                    return [];
                })
                .catch((err) => {
                    console.error(err);
                    return [];
                });
        }
        return Promise.resolve([]);
    } catch {
        return Promise.resolve([]);
    }
}
