import { CookieValidateResponse } from './types/myaccount-cookie';

declare global {
    interface Window {
        kmxDevTools: {
            ciamId: string;
            expireTime: string;
        };
    }
}

export async function getCiamId(): Promise<string | null> {
    if (window?.kmxDevTools?.ciamId) {
        return Promise.resolve(window.kmxDevTools.ciamId);
    }

        return await fetch('/myaccount/token/svc/cookie/validate/low', {
            credentials: 'include'
        })
            .then(response => response.json())
            .then((data: CookieValidateResponse) => {
                if (data.ciamId !== null && data.expireTime !== null) {
                    const expireTime = new Date(data.expireTime);
                    const currentDate = new Date();
                    if (expireTime.getTime() > currentDate.getTime()) {
                        return data.ciamId;
                    }
                }
                return null;
            })
            .catch(() => null);
}
