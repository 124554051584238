import * as React from 'react';
import classNames from 'classnames';
import initCarousel from '../../carousel/index';
import { TinySliderInstance, TinySliderInfo } from 'tiny-slider/src/tiny-slider';
import { largeBreakpoint, mediumBreakpoint } from '../../home-base-hero-carousel/constants';

interface ICarouselProps {
    children: any; //React.ReactElement[];
    name: string;
    nextFocus: string;
    ariaLabel?: string;
    leftAligned?: boolean;
}

interface ICarouselState {
    index: number;
    slideBy: number;
    slideCount: number;
    showNextButton: boolean;
    showPrevButton: boolean;
}

interface INextPrevHoverData {
    slideIsHovered: boolean;
    nextButtonIsHovered: boolean;
    prevButtonIsHovered: boolean;
    slideIndex: number;
    el: any;
}

const ACTIVE_SLIDE_COUNT = 4;
const SLIDE_GUTTER_WIDTH = 8;

export default class Carousel extends React.Component<ICarouselProps, ICarouselState> {
    constructor(props: any) {
        super(props);

        this.state = {
            index: 0,
            slideBy: 0,
            slideCount: 0,
            showNextButton: false,
            showPrevButton: false,
        };

        this.handleIndexChanged = this.handleIndexChanged.bind(this);
        this.initTinySlider = this.initTinySlider.bind(this);
    }

    private _tinySlider?: TinySliderInstance;
    private _removeEventHandlers?: () => void;

    public componentDidMount(): void {
        this.initTinySlider(this.props.name, this.props.nextFocus);
        if (this._tinySlider) {
            const sliderInfo = this._tinySlider.getInfo();
            this.setState({
                index: sliderInfo.index,
                slideBy: sliderInfo.slideBy,
                slideCount: sliderInfo.slideCount,
            });
        }
    }

    public componentWillUnmount(): void {
        if (this._tinySlider) this._tinySlider.destroy();
        if (this._removeEventHandlers) this._removeEventHandlers();
    }

    private initTinySlider(name: string, nextFocus: string): void {
        const { children } = this.props;
        const { tinySlider, removeEventHandlers } = initCarousel({
            nextFocus: nextFocus,
            container: `#${name}-carousel-slides`,
            autoWidth: true,
            nextButton: `#${name}-carousel__next`,
            prevButton: `#${name}-carousel__prev`,
            prevFocus: `#pre-${name}-carousel`,
            gutter: SLIDE_GUTTER_WIDTH,
            slideBy: 'page',
            items: ACTIVE_SLIDE_COUNT,
            responsive: {
                [largeBreakpoint]: {},
                [mediumBreakpoint]: {
                    center: false,
                    gutter: 24,
                },
                0: {
                    center: children.length > 1 && !this.props.leftAligned ? true : false,
                },
            },
        });

        this._tinySlider = tinySlider;
        this._removeEventHandlers = removeEventHandlers;
        if (this._tinySlider) {
            this._tinySlider.events.on('indexChanged', this.handleIndexChanged);
        }
    }

    private handleIndexChanged(info: TinySliderInfo) {
        this.setState({ index: info.index, slideBy: info.slideBy, slideCount: info.slideCount });
    }

    private showSlideNavButton(hoverEvent: INextPrevHoverData): void {
        const firstVisibleTileIndex = this.state.index - 1;
        const targetClientRects = hoverEvent.el.getBoundingClientRect();

        function firstSlideIsHovered() {
            return hoverEvent.slideIsHovered && hoverEvent.slideIndex <= firstVisibleTileIndex;
        }

        function lastSlideIsHovered() {
            const EXTRA_THRESHOLD = 80;
            const vehicleTileLocationPlusExtraThreshold =
                targetClientRects.x + targetClientRects.width + (SLIDE_GUTTER_WIDTH + EXTRA_THRESHOLD);
            return targetClientRects.x < window.innerWidth && vehicleTileLocationPlusExtraThreshold > window.innerWidth;
        }

        this.setState({
            showNextButton: lastSlideIsHovered() || hoverEvent.nextButtonIsHovered ? true : false,
            showPrevButton: firstSlideIsHovered() || hoverEvent.prevButtonIsHovered ? true : false,
        });
    }

    private hideSlideNavButton(): void {
        if (this.state.showNextButton === true || this.state.showPrevButton === true) {
            this.setState({
                showNextButton: false,
                showPrevButton: false,
            });
        }
    }

    componentDidUpdate(prevProps: ICarouselProps) {
        if (this._tinySlider && this.props.children.length !== prevProps.children.length) {
            this._tinySlider.rebuild();
        }
    }

    public render(): React.ReactNode {
        const { children, name, nextFocus } = this.props;
        const { showNextButton, showPrevButton } = this.state;

        return (
            <>
                <a id={`pre-${name}-carousel`} className="skip-link" href={nextFocus}>
                    {`Skip ${this.props.ariaLabel ? this.props.ariaLabel : name.replace(/-/g, ' ')} Carousel`}
                </a>
                <div
                    className={classNames('carousel max-width--xl', {
                        ['scrollable']: showPrevButton && showNextButton,
                    })}
                >
                    <div
                        id={`${name}-carousel-slides`}
                        data-carousel-length={React.Children.count(children)}
                        className={classNames('carousel__slides', {
                            ['carousel-slides__centered']: children.length < 5,
                        })}
                    >
                        {React.Children.map(children, (child: React.ReactChild, index: number) => (
                            <div
                                className="carousel__slide"
                                data-is-current-slide={this.state.index === index ? true : false}
                                key={`${index}-slide`}
                                onMouseEnter={(event: any) =>
                                    this.showSlideNavButton({
                                        slideIsHovered: true,
                                        nextButtonIsHovered: false,
                                        prevButtonIsHovered: false,
                                        slideIndex: index,
                                        el: event.target,
                                    })
                                }
                                onMouseLeave={() => this.hideSlideNavButton()}
                            >
                                {child}
                            </div>
                        ))}
                    </div>
                </div>

                <button
                    id={`${name}-carousel__prev`}
                    className={classNames('kmx-fab kmx-fab--secondary carousel__prev', {
                        'carousel-prev-button__hidden': !showPrevButton,
                    })}
                    onMouseEnter={(event: any) =>
                        this.showSlideNavButton({
                            slideIsHovered: false,
                            nextButtonIsHovered: false,
                            prevButtonIsHovered: true,
                            slideIndex: this.state.index,
                            el: event.target,
                        })
                    }
                    onMouseLeave={() => this.hideSlideNavButton()}
                    tabIndex={-1}
                >
                    <span className="visually-hidden">Previous Slide</span>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
                        <path d="M0-.5h24v24H0z" fill="none" />
                    </svg>
                </button>
                <button
                    id={`${name}-carousel__next`}
                    className={classNames('kmx-fab kmx-fab--secondary carousel__next', {
                        'carousel-next-button__hidden': !showNextButton,
                    })}
                    onMouseEnter={(event: any) =>
                        this.showSlideNavButton({
                            slideIsHovered: false,
                            nextButtonIsHovered: true,
                            prevButtonIsHovered: false,
                            slideIndex: this.state.index,
                            el: event.target,
                        })
                    }
                    onMouseLeave={() => this.hideSlideNavButton()}
                    tabIndex={-1}
                >
                    <span className="visually-hidden">Next Slide</span>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                        <path d="M0-.25h24v24H0z" fill="none" />
                    </svg>
                </button>
            </>
        );
    }
}
