import { IVehicleTile } from '../../../../types/vehicleTile';

export const mapVehicleTileToCar = (vehicleTile: IVehicleTile) => {
    return {
        ...vehicleTile,
        imageCount: 1, // TODO
        highlights: [], // TODO
        transferText: vehicleTile.shippingText,
    };
};

export const hideSnackBarFromExternalScript = () => {
    const externalSnackbar = document.getElementById('parking-lot-snackbar');
    if (externalSnackbar) {
        externalSnackbar.style.display = 'none';
        setTimeout(() => {
            externalSnackbar.style.display = 'block';
        }, 2900);
    }
};
