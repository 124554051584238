import { CustomerProgressionStatusResponse } from './types/customer-progression';

export const emptyProgressionStatus: CustomerProgressionStatusResponse = {
    status: '',
    stockNumber: 0
};

export async function getProgressionStatus(ciamId: string): Promise<CustomerProgressionStatusResponse> {
    if (ciamId) {
        return await fetch(`/home/api/customerprogression/status?ciamId=${ciamId}`)
            .then((response: Response) => response.json())
            .then((data: CustomerProgressionStatusResponse) => data)
            .catch(() => emptyProgressionStatus);
    }

    return Promise.resolve(emptyProgressionStatus);
}
