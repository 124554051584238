import axios from 'axios';
import { IVehicleRecommendationsApiResponse, Vehicle } from './types';

// TODO: Set this max. value on the server
const maxRecommendations = 10;

export async function getVehicleRecommendations(
    stockNumberList: string[],
    lat: number,
    long: number,
    visitorId: string
): Promise<Vehicle[]> {
    try {
        if (stockNumberList && stockNumberList.length > 0) {
            const sortedStockNumberList = stockNumberList.sort();
            const url = `/home/api/recommendations/?visitorId=${visitorId}&lat=${lat}&lon=${long}&stockNumbers=${sortedStockNumberList.reduce(
                (f, s) => `${f}&stockNumbers=${s}`
            )}`;
            return await fetch(url)
                .then((response) => response.json())
                .then((data: IVehicleRecommendationsApiResponse) => {
                    if (data && data.length > 0) {
                        return data.slice(0, maxRecommendations);
                    }
                    return [];
                })
                .catch(() => []);
        }
        return Promise.resolve([]);
    } catch {
        return Promise.resolve([]);
    }
}
