import * as React from 'react';
import classNames from 'classnames';
import Icons from '../../utilities/icons';

interface IValidatedAreaProps {
    overlayIcon: boolean;
    invalid: boolean;
    validationMessage: string;
    className: string;
    children: React.ReactElement;
}

function ValidatedArea(props: IValidatedAreaProps) {
    return (
        <div
            className={classNames(props.className, {
                invalid: props.invalid,
                overlayIcon: props.overlayIcon,
            })}
        >
            <div className="top">
                <div className="questionWrapper">{props.children}</div>
                {props.invalid && (
                    <div className="validationIconOuter">
                        <div className="validationIconInner">{Icons.IconWarning}</div>
                    </div>
                )}
            </div>
            {props.invalid && (
                <div className="mdc-text-field-helper-text validationMessage">{props.validationMessage}</div>
            )}
        </div>
    );
}

export default ValidatedArea;
