import { Vehicle } from './types';

interface RequestParams {
    stockNumberList?: number[];
}

export async function getVehiclesFromRVC(params: RequestParams): Promise<Vehicle[]> {
    try {
        const urlsToFetch: string[] = [];
        if (params.stockNumberList && params.stockNumberList.length > 0) {
            params.stockNumberList.forEach((stockNumber) => {
                urlsToFetch.push(`home/api/vehicles/catalog?StockNumber=${stockNumber}`);
            });
        }
        const fetchPromises = urlsToFetch.map((url) => fetch(url).then((response) => response.json()));
        const response = await Promise.all(fetchPromises);
        if (response.length > 0) {
            return response;
        } else {
            return [];
        }
    } catch {
        return Promise.resolve([]);
    }
}
