import * as React from 'react';
import { placeholderTileIcon } from '../../../pages/home/utilities/svg';
import Carousel from './Carousel';
import { SkeletonVehicleTile } from './SkeletonVehicleTile';

export const RecentlyViewedPlaceholderCarousel: React.FC = () => {
    return (
        <div className="home-base-hero--tab-placeholder home-base-hero--viewed-tab-placeholder">
            <ul className="tab-placeholder--list kmx-list">
                <Carousel
                    name="viewed-placeholder"
                    nextFocus="#budget-calculator-section"
                    ariaLabel="No Viewed Vehicles Available"
                >
                    <li className="tab-placeholder--list-item carousel__slide" key="viewed-placeholder-tile-1">
                        <div className="carousel-slide-item">
                            <div className="tab-placeholder--icon">{placeholderTileIcon}</div>
                            <div className="tab-placeholder--supportive-copy kmx-typography--display-3">
                                Nothing feeling quite right?
                            </div>
                            <a href="/cars" className="tab-placeholder--cta-button kmx-button kmx-button--primary">
                                Browse for cars
                            </a>
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="viewed-placeholder-tile-2"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="viewed-placeholder-tile-3"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="viewed-placeholder-tile-4"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                </Carousel>
            </ul>
        </div>
    );
};
