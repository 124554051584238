import axios from 'axios';
import { getStoreId } from './../utilities/visitor-cookie';
import { DisclaimersApiResponse } from './types';

export function getStatePricingDisclaimer(): Promise<string> {
    const storeId = getStoreId();

    if (storeId) {
        try {
            return axios
                .get<DisclaimersApiResponse>(`/home/api/disclaimers`, {
                    params: { storeId },
                })
                .then(response => {
                    if (response.data.feeDisclaimer) {
                        return response.data.feeDisclaimer;
                    }

                    return Promise.resolve('');
                })
                .catch(() => '');
        } catch {
            return Promise.resolve('');
        }
    }

    return Promise.resolve('');
}
