import * as financeCalculatorUtils from './utils/financeCalculatorUtils';
import { ITaxesAndFees } from '../../../api/taxes-and-fees-api';

export enum FinancingUpdateTypes {
    NearestStoreState = 'NEAREST_STORE_STATE',
    MonthlyPayment = 'MONTHLY_PAYMENT',
    DownPayment = 'DOWN_PAYMENT',
    InterestRate = 'INTEREST_RATE',
    LoanTerm = 'LOAN_TERM'
}

export interface IFinancingUpdate {
    type: FinancingUpdateTypes;
    value: string | number;
}

export interface IFinanceCalculatorInitialValues {
    name: string;
    nearestStoreState: string;
    monthlyPayment: number;
    downPayment: number;
    annualInterestRate: number;
    loanTerm: number;
    taxesAndFeesList: ITaxesAndFees[] | [];
}

export interface IFinanceCalculator {
    id: number;
    name: string;
    nearestStoreState: string;
    monthlyPayment: number;
    downPayment: number;
    annualInterestRate: number;
    loanTerm: number;
    totalFees: number;
    vehiclePrice: number;
    totalPrice: number;
    financePrice: number;
    update(taxesAndFeesList: ITaxesAndFees[], financingUpdate: IFinancingUpdate): number;
}

export default class FinanceCalculator implements IFinanceCalculator {
    private _id: number;
    private _name: string;
    private _nearestStoreState: string;
    private _monthlyPayment: number;
    private _downPayment: number;
    private _annualInterestRate: number;
    private _loanTerm: number;
    private _totalFees: number;
    private _vehiclePrice: number;
    private _totalPrice: number;
    private _financePrice: number;
    private _taxesAndFeesList: ITaxesAndFees[] | [];

    constructor(initialValues: IFinanceCalculatorInitialValues) {
        this._id = Date.now();
        this._name = initialValues.name;
        this._nearestStoreState = initialValues.nearestStoreState;
        this._monthlyPayment = initialValues.monthlyPayment;
        this._downPayment = initialValues.downPayment;
        this._annualInterestRate = initialValues.annualInterestRate;
        this._loanTerm = initialValues.loanTerm;
        this._totalFees = 0;
        this._vehiclePrice = 0;
        this._totalPrice = 0;
        this._financePrice = 0;
        this._taxesAndFeesList = initialValues.taxesAndFeesList;

        this.calculateAndSetAllPrices();
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get nearestStoreState(): string {
        return this._nearestStoreState;
    }

    get monthlyPayment(): number {
        return this._monthlyPayment;
    }

    get downPayment(): number {
        return this._downPayment;
    }

    get annualInterestRate(): number {
        return this._annualInterestRate;
    }

    get loanTerm(): number {
        return this._loanTerm;
    }

    get totalFees(): number {
        return this._totalFees;
    }

    get vehiclePrice(): number {
        return this._vehiclePrice;
    }

    get totalPrice(): number {
        return this._totalPrice;
    }

    get financePrice(): number {
        return this._financePrice;
    }

    private updateId(): void {
        ++this._id;
    }

    private calculateAndSetAllPrices(): void {
        const vehiclePriceAndTotalFees = financeCalculatorUtils.getVehiclePriceAndTotalFees(this._taxesAndFeesList, this);

        this._vehiclePrice = vehiclePriceAndTotalFees.vehiclePrice;
        this._totalFees = vehiclePriceAndTotalFees.totalFees;
        this._totalPrice = financeCalculatorUtils.calculateTotalPrice(this);
        this._financePrice = financeCalculatorUtils.calculateFinancePrice(this);
    }

    public update(taxesAndFeesList: ITaxesAndFees[], financingUpdate: IFinancingUpdate): number {
        switch (financingUpdate.type) {
            case FinancingUpdateTypes.NearestStoreState:
                this._nearestStoreState = String(financingUpdate.value);
                break;
            case FinancingUpdateTypes.MonthlyPayment:
                this._monthlyPayment = Number(financingUpdate.value);
                break;
            case FinancingUpdateTypes.DownPayment:
                this._downPayment = Number(financingUpdate.value);
                break;
            case FinancingUpdateTypes.InterestRate:
                this._annualInterestRate = Number(financingUpdate.value);
                break;
            case FinancingUpdateTypes.LoanTerm:
                this._loanTerm = Number(financingUpdate.value);
                break;
            default:
                console.log('FinanceCalculator: Type not found: ', financingUpdate.type);
                return this._id;
        }

        this._taxesAndFeesList = taxesAndFeesList;
        this.calculateAndSetAllPrices();
        this.updateId();

        return this._id;
    }
}
