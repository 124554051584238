import { useState, useEffect, RefObject } from 'react';

export const useIntersectionObserver = (ref: RefObject<HTMLElement>, threshold: number) => {
    const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

    useEffect(() => {
        const element = ref.current;

        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                const entry = entries[0];
                setIsIntersecting(entry.isIntersecting);
            },
            {
                threshold: threshold,
            }
        );

        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [ref, threshold]);

    return isIntersecting;
};
