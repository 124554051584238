import Carousel from '../../../components/carousels/home-base-hero-carousel/Carousel';
import { LocalCarsSkeletonVehicleTile } from './LocalCarsSkeletonVehicleTile';

interface ILocalCarsPlaceholderCarouselProps {
    isLoading: boolean;
    children?: React.ReactChildren;
}

export const LocalCarsPlaceholderCarousel = (props: ILocalCarsPlaceholderCarouselProps) => {
    return (
        <div className="local-cars-placeholder">
            <Carousel
                name="local-cars-loading-placeholder"
                nextFocus="#social-proof"
                ariaLabel="Loading local cars"
                leftAligned={true}
            >
                {Array.from({ length: 5 }, (_, index) => index + 1).map((index) => (
                    <article
                        className="local-cars-placeholder--item local-cars-placeholder--skeleton carousel__slide"
                        key={`local-cars-placeholder-tile-${index + 1}`}
                    >
                        <div className="carousel-slide-item">
                            <LocalCarsSkeletonVehicleTile isLoading={props.isLoading} />
                        </div>
                    </article>
                ))}
            </Carousel>
        </div>
    );
};
