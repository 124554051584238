import axios from 'axios';

export const getInstantOffersToken = () => {
    return axios({
        url: '/home/api/instantoffers/token',
        method: 'POST'
    }).then(result => {
        return result.data;
    });
};
