import axios from 'axios';
import { RelatedContentResponse } from './types/research-related-content-response';

const emptyRelatedContentResponse = {
    data: []
};
export function getRelatedContent(): Promise<RelatedContentResponse> {
    try {
        return axios
            .get<RelatedContentResponse>(`/home/api/research/related-content`)
            .then(response => {
                if (response.data) {
                    return response.data;
                }
                return Promise.resolve(emptyRelatedContentResponse);
            })
            .catch(() => emptyRelatedContentResponse);
    } catch (e) {
        return Promise.resolve(emptyRelatedContentResponse);
    }
}
