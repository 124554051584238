import { getInstantOffersToken } from '../../../api/instant-offers-api';

export const instantOffersSectionId = 'online-offer';
export const instantOffersContainerId = 'instant-cash-offer';
export const instantOffersDialogContainerClassName = 'instant-offers-dialog-container';
export const instantOffersDisclaimerId = 'instant-offers-disclaimer';
export const instantOffersComponentContainerClass = 'ico-container';

export const instantOfferStartedEvent = 'icoStarted';
export const instantOfferEligibleEvent = 'offerAvailable';
export const instantOfferIneligibleEvent = 'vehicleIneligible';
export const instantOfferZipCodeDecodeErrorEvent = 'zipCodeDecodeError';

export const instantOfferIneligibleAnalyticsEvent = 'Instant-Cash-Offer_Ineligible';

export const buildScheduleIneligibleOfferLink = (originPage = 'HP_ICO_Ineligible') =>
    `/sell-my-car?op=${originPage}#AppointmentForm`;
export const buildScheduleEligibleOfferLink = (storeId: string, offerCode: string, originPage = 'HP_ICO_ActiveOffer') =>
    `/appraisal-checkout?storeId=${storeId}&appraisalCode=${offerCode}&originPage=${originPage}`;

export const makeModelMaxLength = 20;

const icoFeatures = ['condition-question-email'];
const originPage = 'HP_ICO_NewOffer';

interface IcoConfiguration {
    vehicleInformation?: IVehicleInfo;
    containerId: string;
    originPage?: string;
    startingMethod?: string;
    authRefreshHandler: () => Promise<string>;
    enabledFeatures: string[];
    handleIneligible: boolean;
}

interface IVehicleInfo {
    vin: string;
    plate: string;
    state: string;
    mileage: number;
    zipcode: string;
    profile: IProfile;
    styleCode: string;
}

interface IProfile {
    year: string;
    yearCode: string;
    make: string;
    makeCode: string;
    model: string;
    modelCode: string;
    trim: string;
    trimCode: string;
}

export const buildInstantOfferConfig = (
    vehicleInformation?: any,
    type?: string,
    containerId = instantOffersContainerId
): IcoConfiguration => {
    const config: IcoConfiguration = {
        authRefreshHandler: function () {
            return getInstantOffersToken();
        },
        containerId: containerId,
        originPage: originPage,
        enabledFeatures: icoFeatures,
        handleIneligible: true,
    };

    if (vehicleInformation) {
        config.vehicleInformation = vehicleInformation;
    }

    switch (type) {
        case 'PLATE':
            config.startingMethod = 'LP';
            break;
        case 'VIN':
            config.startingMethod = 'VIN';
            break;
    }

    return config;
};

export type AUTOCOMPLETE =
    | 'off'
    | 'on'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'organizational-title'
    | 'organization'
    | 'street-address'
    | 'street-address address-line1'
    | 'street-address address-line2'
    | 'street-address address-line3'
    | 'address-level1'
    | 'address-level2'
    | 'country'
    | 'postal-code'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-extension'
    | 'url';

export type INPUTMODE = 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
export type INPUT_TYPES = 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';
