import { useEffect, useRef, ReactNode } from 'react';
import { useIntersectionObserver } from '../../utilities/hooks';
import './FadeInOnScroll.scss';

type Props = {
    children: ReactNode;
};

export const FadeInOnScroll = ({ children }: Props) => {
    const wrapper = useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(wrapper, 0);

    useEffect(() => {
        if (isIntersecting) {
            wrapper.current?.classList.add('fadein-on-scroll--active');
        }
    }, [isIntersecting]);

    return (
        <div ref={wrapper} className="fadein-on-scroll">
            {children}
        </div>
    );
};
