import * as React from 'react';
import { HomebaseTab, HOMEBASE_CAROUSELS, LOADING_SPINNER_CLASS } from '../../../utilities/constants';
import { IRecentSearchTile } from '../../../utilities/types';
import ReturnVisitorRecentSearchesCarousel from '../../../../../components/carousels/home-base-hero-carousel/ReturnVisitorRecentSearchesCarousel';
import { SearchedPlaceholderCarousel } from '../../../../../components/carousels/home-base-hero-carousel/SearchedPlaceholderCarousel';
import LoadingSpinner from '../../../../../components/loading-spinner';

interface ISearchedCarouselProps {
    isVisible: boolean;
    recentSearchesList: IRecentSearchTile[];
    isLoadingSearchedTab: boolean;
    hasLoadedSearchedTab: boolean;
    leftAligned: boolean;
}

const SearchedCarousel = (props: ISearchedCarouselProps) => {
    const { isVisible, recentSearchesList, isLoadingSearchedTab, hasLoadedSearchedTab, leftAligned } = props;

    return hasLoadedSearchedTab && recentSearchesList.length > 0 ? (
        <ReturnVisitorRecentSearchesCarousel
            carouselName={HOMEBASE_CAROUSELS.byId[HomebaseTab.Searched].carouselName}
            analyticsName={HOMEBASE_CAROUSELS.byId[HomebaseTab.Searched].carouselAnalyticsName}
            isVisible={isVisible}
            recentSearchTileList={recentSearchesList}
            ariaLabel={HOMEBASE_CAROUSELS.byId[HomebaseTab.Searched].ariaLabel}
            leftAligned={leftAligned}
        />
    ) : isLoadingSearchedTab ? (
        <LoadingSpinner className={LOADING_SPINNER_CLASS} />
    ) : hasLoadedSearchedTab && recentSearchesList.length === 0 ? (
        <SearchedPlaceholderCarousel />
    ) : null;
};

export default SearchedCarousel;
