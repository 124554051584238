import { useLocalCarsStore } from '../../../../context/localCarsStore';
import { PlaceholderCarousel } from './PlaceholderCarousel';
import { CarTileWrapper } from '../../../../pages/home/hero/car-tile/CarTileWrapper';
import BaseCarousel from './BaseCarousel';
import { IVehicleTile } from '../../../../types/vehicleTile';
import { shiftUnavailableVehiclesToEnd } from '../../../../pages/home/utilities/helpers';
import { MiniHomebaseSlideCTA } from '../../../../pages/buying-online/components/MiniHomebaseSlideCTA';
import { useEffect, useCallback } from 'react';
import LoadingSpinner from '../../../loading-spinner';

interface ICarouselProps {
    isLoading?: boolean;
    carouselId: string;
    title?: {
        text: string;
        isColorInverse?: boolean;
    };
    placeholder?: {
        cardText: string;
        buttonText: string;
    };
    vehiclesData: IVehicleTile[];
    isMiniHomebase?: boolean; // TODO: MiniHomebase component use this. Need to replace ReturnVisitorCarousel found in that file with this new component
    isRemoveEnabled?: boolean;
    onRemove: (vehicle: IVehicleTile) => void;
    nextFocus?: string;
    isInsideHomeBase?: boolean;
}

export const CarTilesCarousel = ({
    isLoading = false,
    carouselId,
    title,
    placeholder,
    vehiclesData,
    isMiniHomebase,
    isRemoveEnabled,
    onRemove,
    nextFocus,
    isInsideHomeBase,
}: ICarouselProps) => {
    const { fbsData, setFavoriteVehicles, setFbsData } = useLocalCarsStore();

    const sortedVehicleList: IVehicleTile[] = shiftUnavailableVehiclesToEnd(vehiclesData);

    const vehicleCount = vehiclesData?.length;

    const saleableVehicleCount = vehiclesData?.filter((item: IVehicleTile) => item.isSaleable).length;

    const handleRemove = useCallback(
        (vehicle: IVehicleTile) => {
            const remove = vehiclesData.find((v) => v.stockNumber === vehicle.stockNumber);
            if (remove) {
                onRemove(remove);
            }
        },
        [onRemove, vehiclesData]
    );

    const buildCarouselItems = () => {
        const items = sortedVehicleList
            .filter((item) => item !== null && Boolean(item))
            .map((vehicle: IVehicleTile, index: number) => {
                const { stockNumber }: IVehicleTile = vehicle;
                return (
                    <div className="carousel__slide" key={`${stockNumber}-slide-${index}`}>
                        <div className="carousel-slide-item">
                            <CarTileWrapper
                                vehicle={vehicle as IVehicleTile}
                                updatedViewedTiles={handleRemove}
                                enableRemove={isRemoveEnabled ? true : false}
                                isWaitingForFavoritesResponse={
                                    false //this.props.stockNumbersPendingFavoritesUpdate.indexOf(stockNumber) > -1
                                }
                            />
                        </div>
                    </div>
                );
            });

        if (isMiniHomebase) {
            items.push(
                <MiniHomebaseSlideCTA
                    headline={sortedVehicleList.length > 1 ? 'None of these quite right?' : 'Not quite right?'}
                    hasFbsData={fbsData && fbsData.length > 0}
                />
            );
        }
        return items;
    };

    useEffect(() => {
        setFavoriteVehicles();
    }, [setFavoriteVehicles]);

    useEffect(() => {
        setFbsData(vehiclesData.map(({ stockNumber }: { stockNumber: number }) => stockNumber));
    }, [setFbsData, vehiclesData]);

    if (isLoading) {
        return <LoadingSpinner className="loading-spinner" />;
    }

    return (
        <>
            <div
                id={carouselId}
                className="carousel--container"
                data-vehicle-count={vehicleCount}
                data-saleable-vehicle-count={saleableVehicleCount}
            >
                <div className={`carousel--grid ${isInsideHomeBase ? 'is-inside-homebase' : ''}`}>
                    {title && (
                        <h1 className={`carousel--title ${title?.isColorInverse ? 'inverse' : ''}`}>{title?.text}</h1>
                    )}
                    {vehicleCount === 0 && (
                        <PlaceholderCarousel
                            carouselName={carouselId}
                            cardText={placeholder?.cardText}
                            buttonText={placeholder?.buttonText}
                        />
                    )}

                    <BaseCarousel
                        name={carouselId}
                        leftAligned={true}
                        nextFocus={`#${nextFocus}`}
                        ariaLabel={carouselId}
                    >
                        {buildCarouselItems()}
                    </BaseCarousel>
                </div>
            </div>
        </>
    );
};
