import { IVehicleTile } from '../../../types/vehicleTile';
import { viewedVehiclesLocalStorage } from './constants';
import { IViewedVehicleLocalStorage } from './types';

const transformStockNumberTypeForLocalStorage = (stockNumber: number): string => {
    return stockNumber.toString();
};

export const removeViewedVehicleItemLocalStorage = (tileToRemove: IVehicleTile): void => {
    const serializedViewedVehicles = localStorage.getItem(viewedVehiclesLocalStorage.key) as string;

    const newLocalStorage: IViewedVehicleLocalStorage[] = [];

    if (serializedViewedVehicles?.length) {
        JSON.parse(serializedViewedVehicles).forEach((tileData: IViewedVehicleLocalStorage) => {
            const tileDataString = transformStockNumberTypeForLocalStorage(tileData.stockNumber);
            const tileToRemoveString = transformStockNumberTypeForLocalStorage(tileToRemove.stockNumber);

            if (tileDataString === tileToRemoveString) {
                return;
            } else {
                newLocalStorage.push(tileData);
            }
        });
    } else {
        console.log(
            `Vehicle ${tileToRemove.stockNumber} was not found in the user's viewed vehicles. It may be a vehicle that was only saved/favorited.`
        );
    }

    window.localStorage.setItem(viewedVehiclesLocalStorage.key, JSON.stringify(newLocalStorage));
};
