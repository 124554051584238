import * as React from 'react';
import classNames from 'classnames';
import { IVehicleTypeTile } from '../../../pages/home/budget-calculator/utils/types';
import { budgetSearch } from '../../../pages/home/budget-calculator/utils/content';

interface IVehicleTypeTileProps {
    selected: boolean;
    vehicleType: IVehicleTypeTile;
    itemIndex: number;
    onClick: (type: IVehicleTypeTile) => any;
}

const VehicleTypeTile = (props: IVehicleTypeTileProps) => {
    return (
        <button
            key={props.vehicleType.pathSegment}
            data-index={props.itemIndex}
            className={classNames('vehicle-type-tile', {
                ['selected']: props.selected
            })}
            onClick={(e: any) => props.onClick(props.vehicleType)}
        >
            <div
                className={classNames('selected-check', {
                    'selected-check--visible': props.selected
                })}
            >
                <div className="check-svg-container">
                    <svg
                        viewBox="0 0 24 24"
                        height="24"
                        width="16"
                        fill="#fff"
                        aria-hidden="true"
                        className="check-svg"
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                    </svg>
                </div>
            </div>

            <img
                alt=""
                className="vehicle-type-tile-icon"
                src={props.vehicleType.imgSrc}
                width={budgetSearch.vehicleTypeCarouselSelect.vehicleTypeTileImageWidth}
                height={budgetSearch.vehicleTypeCarouselSelect.vehicleTypeTileImageHeight}
            />
            <div className="vehicle-type-tile-title">{props.vehicleType.label}</div>
        </button>
    );
};

export default VehicleTypeTile;
