import {
    returnVisitorLocalStorage,
    viewedVehiclesLocalStorage,
    IReturnVisitorLocalStorage,
    VehicleStatuses,
    soldVehicleStatusCodes,
    onTestDriveStatusCodes,
    reservedStatusCodes,
    shippingStatusCodes,
    otherUnavailableStatusCodes,
    reservedConditionalStatusCodes,
    shippingConditionalStatusCodes,
    DAYS_BEFORE_HOMEBASE_VISITOR_EXPIRES,
} from './constants';
import { IVehicleTile } from '../../../types/vehicleTile';
import { IRecentSearchLocalStorage, IViewedVehicleLocalStorage } from './types';

export const deviceIsApple = (): boolean => {
    if (
        window.navigator.userAgent &&
        (window.navigator.userAgent.indexOf('Mac OS') != -1 ||
            window.navigator.userAgent.toLowerCase().indexOf('iPhone OS') != -1)
    ) {
        return true;
    }
    return false;
};

export const deviceIsAndroid = (): boolean => {
    if (window.navigator.userAgent && window.navigator.userAgent.toLowerCase().indexOf('android') != -1) {
        return true;
    }
    return false;
};

export const deviceIsWindows = (): boolean => {
    if (window.navigator.userAgent && window.navigator.userAgent.toLowerCase().indexOf('windows') != -1) {
        return true;
    }
    return false;
};

export const isReturnHomePageVisitor = (): boolean => {
    let isFirstVisit = true;

    try {
        const serializedReturnVisitor = localStorage.getItem(returnVisitorLocalStorage.key) as string;
        const returnVisitor = JSON.parse(serializedReturnVisitor) as IReturnVisitorLocalStorage | null;

        if (returnVisitor && typeof returnVisitor === 'boolean') {
            isFirstVisit = false;
        }
    } catch (e) {}

    return !isFirstVisit && hasViewedVehicles();
};

export const trackFirstTimeHomePageVisitor = () => {
    try {
        localStorage.setItem(returnVisitorLocalStorage.key, 'true');
    } catch (e) {}
};

export const getExpirationDate = (): number => {
    const currentDate: Date = new Date();
    return currentDate.setDate(currentDate.getDate() - DAYS_BEFORE_HOMEBASE_VISITOR_EXPIRES);
};

export const getLocalStorageItem = <T>(key: string): T | null => {
    const value: string | null = localStorage.getItem(key);
    return value !== null ? JSON.parse(value) : null;
};

export const filterOutExpiredVehicles = <T extends IViewedVehicleLocalStorage | IRecentSearchLocalStorage>(
    vehicles: T[],
    expirationDate: number
): T[] => {
    return vehicles?.filter(({ timestamp }: T) => timestamp > expirationDate) || [];
};

export const hasViewedVehicles = (): boolean => {
    try {
        // Get viewed vehicles from localStorage
        const viewedVehicles: IViewedVehicleLocalStorage[] = getLocalStorageItem(viewedVehiclesLocalStorage.key) || [];

        // Keep only vehicles seen in the past {DAYS_BEFORE_HOMEBASE_VISITOR_EXPIRES}
        const expirationDate = getExpirationDate();
        const filteredVehicles = filterOutExpiredVehicles(viewedVehicles, expirationDate);

        return filteredVehicles.length > 0;
    } catch (error) {
        console.error('Error retrieving viewed vehicles:', error);
        return false;
    }
};

export const shiftUnavailableVehiclesToEnd = (vehicleTileList: IVehicleTile[]) => {
    const availableVehicles: IVehicleTile[] = [];
    const comingSoonVehicles: IVehicleTile[] = [];
    const onRemoteLotVehicles: IVehicleTile[] = [];
    const beingTransferredVehicles: IVehicleTile[] = [];
    const onTestDriveVehicles: IVehicleTile[] = [];
    const holdVehicles: IVehicleTile[] = [];
    const soldVehicles: IVehicleTile[] = [];
    const otherUnavailableVehicles: IVehicleTile[] = [];
    let sortedVehicleTileList: IVehicleTile[] = [];

    vehicleTileList.forEach((tile, index) => {
        // use the below commented conditions to coerce DevTools recently viewed vehicles to have desired properties and test sorting

        // // 0. make coming soon , doesn't comeback true when created
        // if (index === 0) {
        //     tile.isComingSoon = true;
        // }

        // // 1. make onRemoteLot
        // if (index === 1) {
        //     tile.isOnRemoteLot = true;
        // }

        // // 2. make being transferred
        // if (index === 2) {
        //     tile.statusCode = 100;
        //     tile.isComingSoon = false;
        // }

        // // 3. make on test drive
        // if (index === 3) tile.statusCode = 345;

        // // 4. make on test drive
        // if (index === 4) {
        //     tile.statusCode = 330;
        //     tile.isAvailableToTransact = false;
        // }

        // // 5. make sold
        // if (index === 1) tile.statusCode = 360;

        // 6. make other unavailable
        // if (index === 1) {
        //     tile.statusCode = 720;
        //     tile.isSaleable = false;
        // }

        const status = translateVehicleStatus(tile);

        // 0 - Coming soon
        if (status === VehicleStatuses.ComingSoon) {
            comingSoonVehicles.push(tile);
            return;
        }

        // 1 Appointment Only
        if (status === VehicleStatuses.OffSite) {
            onRemoteLotVehicles.push(tile);
            return;
        }

        // 2 On Test Drive
        if (status === VehicleStatuses.OnTestDrive) {
            onTestDriveVehicles.push(tile);
            return;
        }

        // 3 Shipping
        if (status === VehicleStatuses.Shipping) {
            beingTransferredVehicles.push(tile);
            return;
        }

        // 4 Reserved
        if (status === VehicleStatuses.Reserved) {
            holdVehicles.push(tile);
            return;
        }

        // 5 sold
        if (status === VehicleStatuses.Sold) {
            soldVehicles.push(tile);
            return;
        }

        // other
        if (status === VehicleStatuses.NotAvailable) {
            otherUnavailableVehicles.push(tile);
            return;
        }

        availableVehicles.push(tile);
    });

    sortedVehicleTileList = [
        ...availableVehicles,
        ...comingSoonVehicles,
        ...onRemoteLotVehicles,
        ...onTestDriveVehicles,
        ...beingTransferredVehicles,
        ...holdVehicles,
        ...soldVehicles,
        ...otherUnavailableVehicles,
    ];

    return sortedVehicleTileList;
};

export const translateVehicleStatus = (vehicle: IVehicleTile): string => {
    const { isAvailableToTransact, isComingSoon, isOnRemoteLot, statusCode } = vehicle;
    let status = '';

    if (soldVehicleStatusCodes.includes(statusCode)) {
        status = VehicleStatuses.Sold;
    }

    if (onTestDriveStatusCodes.includes(statusCode)) {
        status = VehicleStatuses.OnTestDrive;
    }

    if (
        reservedStatusCodes.includes(statusCode) ||
        (reservedConditionalStatusCodes.includes(statusCode) && !isAvailableToTransact)
    ) {
        status = VehicleStatuses.Reserved;
    }

    if (
        shippingStatusCodes.includes(statusCode) ||
        (shippingConditionalStatusCodes.includes(statusCode) && !isComingSoon)
    ) {
        status = VehicleStatuses.Shipping;
    }

    if (isComingSoon) {
        status = VehicleStatuses.ComingSoon;
    }

    if (isOnRemoteLot) {
        status = VehicleStatuses.OffSite;
    }

    if (otherUnavailableStatusCodes.includes(statusCode)) {
        status = VehicleStatuses.NotAvailable;
    }

    return status;
};
