import { TinySliderInstance } from 'tiny-slider/src/tiny-slider';

const focusOnActiveCta = (tinySlider: TinySliderInstance) => {
    const activeEl = document.activeElement as HTMLElement | null;
    if (activeEl) {
        activeEl.focus();
    }
};

export default focusOnActiveCta;
