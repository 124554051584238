import { tns, TinySliderInstance, TinySliderSettings } from 'tiny-slider/src/tiny-slider';
import * as constants from './constants';
import addEventHandlers from './helpers';
// no type support for tiny-slider, so use as js module for now

interface ICarousel extends TinySliderSettings {
    nextFocus: string;
    prevFocus: string;
}

export default function initCarousel({
    autoplay = false,
    container,
    fixedWidth = false,
    autoWidth,
    autoHeight,
    items = 1,
    loop = false,
    gutter = 0,
    navContainer,
    nextButton = false,
    nextFocus = '',
    prevButton = false,
    prevFocus = '',
    responsive = false,
    center = false,
}: ICarousel): { tinySlider: TinySliderInstance; removeEventHandlers: () => void } {
    const tinySlider = tns({
        autoplay,
        container, // '.carousel__slides'
        fixedWidth,
        autoWidth,
        items,
        loop,
        navContainer, // '.carousel__nav'
        nextButton,
        prevButton,
        controls: !!nextButton,
        edgePadding: fixedWidth ? 8 : 0,
        nav: !!navContainer,
        responsive,
        gutter: gutter,
        center,
        preventScrollOnTouch: 'auto',
        ...constants,
    });
    const removeEventHandlers = addEventHandlers({ tinySlider, nextFocus, prevFocus });
    return { tinySlider, removeEventHandlers };
}
