import { KmxVisitorObject } from '../utilities/cookieHelper';
import { IFBSDecision } from './types/finance';

export const emptyFBSResponse: IFBSDecision[] = [
    {
        fbsDecision: {
            expirationDateFormatted: '',
            downPayment: 0,
            isSoftPull: true,
            decision: '',
            terms: [],
            conditionAmount: 0,
            conditionAmountRounded: 0,
            lenderName: '',
            totalFinanceAmount: 0,
            stateAbbreviation: '',
            taxesAndFeesRounded: 0,
        },
        stockNumber: 0,
        taxesAndFeesRounded: 0,
        applicationUrl: '',
    },
];

export async function getSmallBatchDecisions(
    ciamId: string,
    stockNumbers: number[],
    storeId?: string
): Promise<IFBSDecision[]> {
    const stockNumbersString = stockNumbers.toString().replace(/,/g, '&stockNumbers=');
    let storeIdToRequest = storeId;

    if (!storeId) {
        const { StoreId } = KmxVisitorObject();
        storeIdToRequest = StoreId;
    }

    if (ciamId.length > 0 && stockNumbers.length > 0) {
        return await fetch(
            `/home/api/finance/smallbatch?ciamId=${ciamId}&stockNumbers=${stockNumbersString}&storeId=${storeIdToRequest}`,
            {
                credentials: 'include',
            }
        )
            .then((response) => response.json())
            .then((data: IFBSDecision[]) => {
                return data;
            })
            .catch((err) => {
                console.log(err);
                return emptyFBSResponse;
            });
    } else {
        return Promise.resolve(emptyFBSResponse);
    }
}
