import { ICreditRate } from '../../../../api/credit-rates-api';

export const hideBudgetSearch = () => {
    const budgetCalculator = document.querySelector('.budget-calculator-section');

    if (budgetCalculator !== null) {
        budgetCalculator.classList.add('budget-calculator-section-hidden');
    }
};

const buildCreditScoreLabel = (creditRate: ICreditRate): string => {
    let creditScoreLabel = '';
    const creditName = creditRate.type.split(/(?=[A-Z])/).join(' ');

    if (creditRate.minimumFicoScore && creditRate.maximumFicoScore) {
        creditScoreLabel = `${creditName} (${creditRate.minimumFicoScore}-${
            creditRate.maximumFicoScore
        } FICO\u00ae Score)`;
    } else if (!creditRate.minimumFicoScore && creditRate.maximumFicoScore) {
        creditScoreLabel = `${creditName} (< ${creditRate.maximumFicoScore} FICO\u00ae Score)`;
    } else if (creditRate.minimumFicoScore && !creditRate.maximumFicoScore) {
        creditScoreLabel = `${creditName} (${creditRate.minimumFicoScore}+ FICO\u00ae Score)`;
    }

    return creditScoreLabel;
};

export interface ICreditScores {
    allIds: string[];
    byId: { [key: string]: { name: string; label: string; value: string } };
}

export const buildCreditScores = (creditRatesList: ICreditRate[]): ICreditScores => {
    const creditScores: ICreditScores = { allIds: [], byId: {} };

    creditRatesList.forEach(creditRate => {
        creditScores.allIds.push(String(creditRate.medianRate));
        creditScores.byId[creditRate.medianRate] = {
            name: creditRate.type,
            label: buildCreditScoreLabel(creditRate),
            value: String(creditRate.medianRate)
        };
    });

    return creditScores;
};
