import LazyLoad from 'react-lazyload';
import { LocalCars } from './LocalCars';
import { isReturnHomePageVisitor } from '../utilities/helpers';

export const LocalCarsLoader: React.FC = () => {
    return !isReturnHomePageVisitor() ? (
        <LazyLoad height={window.innerHeight}>
            <LocalCars />
        </LazyLoad>
    ) : (
        <></>
    );
};
