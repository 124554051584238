export const toDollars = (value: number, includeCents?: boolean): string => {
    if (value) {
        const returnValue = includeCents ? value.toFixed(2) : value.toFixed(0);

        return '$' + returnValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
        return includeCents ? '$0.00' : '$0';
    }
};

export const extractNumberFromString = (value: string): number => {
    if (typeof value === 'string') {
        const number = stripNonNumeric(value);
        return number;
    }
    return value;
};

export const stripNonNumeric = (original: string): number => {
    const value = original;
    const parsedFloat = parseFloat(value.replace(/[^0-9.]+/g, ''));
    return typeof parsedFloat === 'number' && parsedFloat !== parsedFloat ? 0 : parsedFloat;
};
