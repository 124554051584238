import { useEffect, useState } from 'react';
import SearchedCarousel from '../../hero/home-base/carousels/SearchedCarousel';
import { getRecentSearches } from '../../../../api/recent-search-api';
import { IRecentSearch } from '../../../../api/types';
import { useHomeStore } from '../../HeroRefactor/Store';
import { Names } from '../../HeroRefactor/Store/models';

export const RecentSearchesContainer = () => {
    const [hasLoadedSearches, setHasLoadedSearches] = useState(false);
    const [isLoadingSearches, setIsLoadingSearches] = useState(false);
    const [recentSearchesList, setRecentSearchesList] = useState<IRecentSearch[]>([]);

    const { experienceData }: { experienceData: any } = useHomeStore();
    const hasHomeBase = experienceData && experienceData.name === Names.HomeBase;

    useEffect(() => {
        if (!hasLoadedSearches) {
            setIsLoadingSearches(true);
            getRecentSearches().then((recentSearchesTileList) => {
                setIsLoadingSearches(false);
                setHasLoadedSearches(true);
                if (recentSearchesTileList.length > 0) {
                    setRecentSearchesList(recentSearchesTileList);
                }
            });
        }
    }, [hasLoadedSearches]);

    return hasHomeBase && hasLoadedSearches && recentSearchesList.length > 0 ? (
        <>
            <div className="recent-searches--heading">
                <h2>Keep searching for</h2>
            </div>
            <SearchedCarousel
                hasLoadedSearchedTab={hasLoadedSearches}
                isLoadingSearchedTab={isLoadingSearches}
                isVisible={true}
                recentSearchesList={recentSearchesList}
                leftAligned={true}
            />
        </>
    ) : (
        <></>
    );
};
