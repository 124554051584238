import { createContext, ReactNode, useState, useEffect } from 'react';
import { getSmallBatchDecisions } from '../api/small-batch-api';
import { getCiamId } from '../api/myaccount-cookie-api';
import { IVehicleTile } from '../types/vehicleTile';
import { IFBSDecision } from '../api/types/finance';

interface IFBSProviderProps {
    children: ReactNode;
}

export const emptyFbsTermsData = {
    fbsApplication: {
        downPayment: 0,
        expirationDateFormatted: '',
        stateAbbreviation: '',
        stationUrl: '',
    },
    fbsDecision: {
        downPayment: 0,
        isSoftPull: true,
        decision: '',
        lenderName: '',
        totalFinanceAmount: 0,
        terms: [
            {
                length: 0,
                maxTermRate: {
                    monthlyPayment: 0,
                    apr: 0,
                },
                minTermRate: {
                    monthlyPayment: 0,
                    apr: 0,
                },
            },
        ],
        conditionAmount: 0,
        conditionAmountRounded: 0,
        stateAbbreviation: '',
        taxesAndFeesRounded: 0,
    },
    stockNumber: 0,
    vehicleData: {
        basePrice: 0,
        stockNumber: 0,
        year: 0,
        make: '',
        model: '',
        trim: '',
        stateCode: '',
        taxesAndFeesRounded: 0,
    },
    downPayment: 0,
};

const defaultFbsData: IFBSDecision[] = [];
const defaultSetVehicleList = () => {
    // will be replaced by useState
};

interface IFBSContext {
    stationUrl: string;
    downPayment: number;
    enableFBS: boolean;
    fbsData: IFBSDecision[];
    setVehicleList: (vehicleTileList: IVehicleTile[]) => void;
}
const defaultContext: IFBSContext = {
    stationUrl: '',
    downPayment: 0,
    enableFBS: false,
    fbsData: defaultFbsData,
    setVehicleList: defaultSetVehicleList,
};

export const FBSContext = createContext<IFBSContext>(defaultContext);

export const FBSProvider = (props: IFBSProviderProps) => {
    const [enableFBS, setEnableFBS] = useState(false);
    const [vehicleList, setVehicleList] = useState<IVehicleTile[]>([]);
    const [fbsData, setFbsData] = useState<IFBSDecision[]>([]);
    const [downPayment, setDownPayment] = useState<number>(0);
    const [stationUrl, setStationUrl] = useState<string>('');

    useEffect(() => {
        const tryEnablingFBS = async () => {
            const vehicleStockNumbers = vehicleList.reduce((stockNumbers: number[], vehicle: IVehicleTile) => {
                if (vehicle) {
                    return [...stockNumbers, vehicle.stockNumber];
                }
                return stockNumbers;
            }, []);

            const ciamId = await getCiamId();
            if (ciamId) {
                const smallBatchDecisions = await getSmallBatchDecisions(ciamId, vehicleStockNumbers);
                setFbsData((prevFbsData) => [...prevFbsData, ...smallBatchDecisions]);
                if (
                    smallBatchDecisions.length > 0 &&
                    smallBatchDecisions[0].fbsDecision !== null &&
                    smallBatchDecisions[0].stockNumber !== 0 &&
                    smallBatchDecisions[0].fbsDecision.terms !== null
                ) {
                    setDownPayment(smallBatchDecisions[0].fbsDecision.downPayment);
                    setStationUrl(smallBatchDecisions[0].applicationUrl);
                    setEnableFBS(true);
                }
            }
        };

        if (vehicleList.length > 0) {
            tryEnablingFBS();
        }
    }, [vehicleList]);

    return (
        <FBSContext.Provider value={{ ...defaultContext, stationUrl, downPayment, enableFBS, fbsData, setVehicleList }}>
            {props.children}
        </FBSContext.Provider>
    );
};
