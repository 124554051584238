import axios from 'axios';

export interface ITaxesAndFeesApiResponse {
    items: ITaxesAndFees[];
    start: number;
    count: number;
    totalCount: number;
}

export interface ITaxesAndFees {
    minimum: number;
    maximum: number;
    estimatedFeesMinumum: number;
    estimatedFeesMaximum: number;
    details: {
        salesTax: string[];
        title: string[];
        registration: string[];
        plateTransfer: string[];
        additionalFees: string[];
        total: string;
        highFeeEstimate: number;
    };
}

export const getTaxesAndFeesList = (state: string): Promise<ITaxesAndFees[] | null[]> => {
    try {
        return axios
            .get(`/home/api/taxesandfees?state=${state}`)
            .then((response: { data: ITaxesAndFeesApiResponse }) => {
                if (response.data && response.data.items && response.data.items.length > 0) {
                    return response.data.items;
                }
                return [];
            })
            .catch((error: any) => {
                console.error('TaxesAndFeesApi: Error fetching fees: ', error);
                return [];
            });
    } catch (e) {
        return Promise.resolve([]);
    }
};
