import * as ReactDOM from 'react-dom';
import { instantOffersDisclaimerId } from './utils/constants';

export default function InstantOffersDisclaimer() {
    const disclaimerEl = document.getElementById(instantOffersDisclaimerId);

    const disclaimer = (
        <div data-fs-section="HP: Instant Offers Disclaimer" className="hp-layout-grid">
            <div className="mdc-layout-grid__inner disclaimer-section">
                <div className="hp-typography--fine-print mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                    &dagger; The offer from CarMax is contingent on your providing accurate information. CarMax will
                    conduct a verification of your vehicle and evaluate other vehicle use and history information prior
                    to finalizing the offer. Any differences between the information you provide about your vehicle and
                    the vehicle&apos;s actual condition, use, and history may impact the offer you receive from CarMax.
                </div>
            </div>
        </div>
    );

    return disclaimerEl ? ReactDOM.createPortal(disclaimer, disclaimerEl) : null;
}
