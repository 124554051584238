import * as React from 'react';
import classNames from 'classnames';

interface ITabBar {
    className: string;
    id: string;
    children: any;
}

function TabBar(props: ITabBar) {
    const { className, id, children } = props;
    const numTabs = React.Children.toArray(children).length;

    return (
        <div id={id} className={classNames(className, 'tab-bar-container')} role="tablist">
            {React.Children.map(children, (child, index) => React.cloneElement(child, { index, numTabs }))}
        </div>
    );
}

export default TabBar;
