import * as React from 'react';
import * as ReactDOM from 'react-dom';
import InstantOffersFullScreenDialog from './InstantOffersFullScreenDialog';
import InstantOffersDisclaimer from './InstantOffersDisclaimer';
import InstantOffers from './InstantOffers';
import * as analytics from '../../utilities/analytics';
import { getInstantOffersToken } from '../../api/instant-offers-api';
import {
    instantOffersContainerId,
    buildInstantOfferConfig,
    instantOfferEligibleEvent,
    instantOfferIneligibleEvent,
    instantOfferIneligibleAnalyticsEvent,
    instantOfferStartedEvent,
    instantOfferZipCodeDecodeErrorEvent,
} from './utils/constants';
import {
    removeEligibleOfferFromStorage,
    getEligibleOfferFromStorage,
    setIneligibleOfferToStorage,
    getIneligibleOfferFromStorage,
    IEligibleOfferLocalStorage,
    IIneligibleOfferLocalStorage,
} from './utils/instantOffersStorage';

interface IInstantOffersContainerProps {}

interface IInstantOffersContainerState {
    isIneligibleOpen: boolean;
    isIcoLoading: boolean;
    isIcoDialogOpen: boolean;
    isIcoEligible: boolean;
    hasInvalidLicensePlate: boolean;
    savedEligibleOffer: IEligibleOfferLocalStorage | null;
    savedIneligibleOffer: IIneligibleOfferLocalStorage | null;
    vehicleMake?: string;
    vehicleModel?: string;
}

export interface IInstantOffersFormData {
    isValid: boolean;
    type: 'VIN' | 'PLATE';
    state: string;
    zipCode: string;
    vin: string;
    plate: string;
}

export default class InstantOffersContainer extends React.Component<
    IInstantOffersContainerProps,
    IInstantOffersContainerState
> {
    constructor(props: any) {
        super(props);

        const savedEligibleOffer = getEligibleOfferFromStorage(),
            savedIneligibleOffer = getIneligibleOfferFromStorage();

        this.state = {
            isIneligibleOpen: false,
            isIcoLoading: true,
            isIcoDialogOpen: false,
            isIcoEligible: false,
            hasInvalidLicensePlate: false,
            savedEligibleOffer: savedEligibleOffer,
            savedIneligibleOffer: savedIneligibleOffer,
            vehicleMake: savedIneligibleOffer && savedIneligibleOffer.make ? savedIneligibleOffer.make : '',
            vehicleModel: savedIneligibleOffer && savedIneligibleOffer.model ? savedIneligibleOffer.model : '',
        };

        this.handleIcoDialogClose = this.handleIcoDialogClose.bind(this);
        this.handleStartNewOffer = this.handleStartNewOffer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkEligibilityAndLaunch = this.checkEligibilityAndLaunch.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEligibility = this.handleEligibility.bind(this);
        this.handleIneligibility = this.handleIneligibility.bind(this);
        this.handleStarted = this.handleStarted.bind(this);
    }

    private handleIcoDialogClose() {
        this.setState({ isIcoDialogOpen: false }, () => {
            if ((window as any).InstantCashOffers != null) {
                (window as any).InstantCashOffers.cancel(instantOffersContainerId);
            }
        });
    }

    private handleEligibility(message: any) {
        const newOffer = getEligibleOfferFromStorage();
        if (newOffer) {
            this.setState({
                savedIneligibleOffer: null,
                savedEligibleOffer: newOffer,
            });
        }
    }

    private handleIneligibility(newState?: any) {
        removeEligibleOfferFromStorage();
        const savedIneligibleOffer = setIneligibleOfferToStorage();

        const nextState = newState ? newState : {};
        nextState.savedIneligibleOffer = savedIneligibleOffer;
        nextState.isIcoEligible = false;
        nextState.savedEligibleOffer = null;
        nextState.isIcoLoading = false;

        this.setState(nextState, () => {
            analytics.track(instantOfferIneligibleAnalyticsEvent);
        });
    }

    private handleStarted() {
        this.setState({
            isIcoLoading: false,
        });
    }

    private checkEligibilityAndLaunch(decodeResponse: any, zipCode: string, type: IInstantOffersFormData['type']) {
        if (!decodeResponse || !decodeResponse.profile) {
            this.handleIneligibility({
                isIcoLoading: false,
                hasInvalidLicensePlate: true,
            });
        } else {
            (window as any).InstantCashOffers.getEligibility(
                decodeResponse.profile.make,
                decodeResponse.profile.model,
                decodeResponse.profile.year,
                decodeResponse.vin,
                zipCode
            ).then((isEligible: any) => {
                if (isEligible) {
                    decodeResponse.zipcode = zipCode;

                    this.setState(
                        {
                            isIcoEligible: true,
                            vehicleMake: decodeResponse.profile.make,
                            vehicleModel: decodeResponse.profile.model,
                        },
                        () => {
                            (window as any).InstantCashOffers.initialize(buildInstantOfferConfig(decodeResponse, type));
                        }
                    );
                } else {
                    this.handleIneligibility({
                        isIcoLoading: false,
                        vehicleMake: decodeResponse.profile.make,
                        vehicleModel: decodeResponse.profile.model,
                    });
                }
            });
        }
    }

    private handleSubmit(event: any, formData: IInstantOffersFormData) {
        event.preventDefault();

        if (formData.isValid) {
            this.setState(
                {
                    isIcoLoading: true,
                    isIcoDialogOpen: true,
                },
                () => {
                    if (formData.type === 'VIN') {
                        (window as any).InstantCashOffers.decodeVin(formData.vin, function () {
                            return getInstantOffersToken();
                        })
                            .then((resp: any) => this.checkEligibilityAndLaunch(resp, formData.zipCode, formData.type))
                            .catch(() => {
                                this.handleIneligibility({
                                    isIcoLoading: false,
                                    hasInvalidLicensePlate: true,
                                });
                            });
                    } else if (formData.type === 'PLATE') {
                        (window as any).InstantCashOffers.decodePlate(formData.plate, formData.state, function () {
                            return getInstantOffersToken();
                        })
                            .then((resp: any) => this.checkEligibilityAndLaunch(resp, formData.zipCode, formData.type))
                            .catch(() => {
                                this.handleIneligibility({
                                    isIcoLoading: false,
                                    hasInvalidLicensePlate: true,
                                });
                            });
                    }
                }
            );
        }
    }

    private handleStartNewOffer() {
        if ((window as any).InstantCashOffers) {
            if (this.state.isIcoDialogOpen) {
                (window as any).InstantCashOffers.cancel(instantOffersContainerId);
            }

            this.setState(
                {
                    isIcoEligible: true,
                    isIcoDialogOpen: true,
                    isIcoLoading: false,
                },
                () => {
                    (window as any).InstantCashOffers.initialize(buildInstantOfferConfig());
                }
            );
        }
    }

    public componentDidMount() {
        (window as any).addEventListener(instantOfferIneligibleEvent, () => this.handleIneligibility());
        (window as any).addEventListener(instantOfferEligibleEvent, this.handleEligibility);
        (window as any).addEventListener(instantOfferStartedEvent, this.handleStarted);
        (window as any).addEventListener(instantOfferZipCodeDecodeErrorEvent, () => this.handleIneligibility());
    }

    public componentWillUnmount() {
        (window as any).removeEventListener(instantOfferIneligibleEvent, () => this.handleIneligibility());
        (window as any).removeEventListener(instantOfferEligibleEvent, this.handleEligibility);
        (window as any).removeEventListener(instantOfferStartedEvent, this.handleStarted);
        (window as any).removeEventListener(instantOfferZipCodeDecodeErrorEvent, () => this.handleIneligibility());
    }

    public render(): React.ReactNode {
        return (
            <>
                <div data-fs-section="HP: Instant Offers" id="io-featured-story-telling-section">
                    <div className="ico-container">
                        <InstantOffers
                            savedOffer={this.state.savedEligibleOffer}
                            hasInvalidLicensePlate={this.state.hasInvalidLicensePlate}
                            onSubmit={this.handleSubmit}
                            hasSavedEligibleOffer={this.state.savedEligibleOffer ? true : false}
                            hasSavedIneligibleOffer={this.state.savedIneligibleOffer ? true : false}
                            instantOfferDetails={{
                                vehicleMake:
                                    this.state.vehicleMake === ''
                                        ? this.state.savedEligibleOffer?.vehicleInformation?.profile?.make
                                        : '',
                                vehicleModel:
                                    this.state.vehicleModel === ''
                                        ? this.state.savedEligibleOffer?.vehicleInformation?.profile?.model
                                        : '',
                                onStartNewOffer: this.handleStartNewOffer,
                            }}
                        />
                    </div>
                </div>
                {ReactDOM.createPortal(
                    <InstantOffersFullScreenDialog
                        isOpen={this.state.isIcoDialogOpen}
                        isLoading={this.state.isIcoLoading}
                        onClose={this.handleIcoDialogClose}
                        isInstantOffersEligible={this.state.isIcoEligible}
                        instantOfferDetails={{
                            vehicleMake: this.state.vehicleMake,
                            vehicleModel: this.state.vehicleModel,
                            onStartNewOffer: this.handleStartNewOffer,
                        }}
                    />,
                    document.getElementById('InstantOffersFullScreenDialogNode')!
                )}
                {this.state.savedEligibleOffer && <InstantOffersDisclaimer />}
            </>
        );
    }
}
