import { useEffect, Suspense, useState, useCallback } from 'react';
import { useHomeStore, HeroContext } from './Store';
import { getComponentAndData } from './services';
import { IExperienceV2, Names } from './Store/models';
import HomeBase from './HomeBase';
import ColdStartCarousel from '../../../components/carousels/cold-start';
import FirstTimeHero from './FirstTimeHero';

function HeroRefactor() {
    const store = useHomeStore();
    const [component, setComponent] = useState<JSX.Element>();

    const ReturnComponent = useCallback((componentName: string) => {
        const bucketName = window?.kmxExperiments?.bucketName;

        switch (componentName) {
            case Names.HomeBase:
                setComponent(<HomeBase />);
                break;
            case Names.FirstTime:
                setComponent(<FirstTimeHero />);
                break;
            case Names.ColdStart:
                setComponent(<ColdStartCarousel bucketName={bucketName} />);
                break;
            default:
                setComponent(<FirstTimeHero />);
                break;
        }
    }, []);

    useEffect(() => {
        if (!store.isLoading) {
            const experienceData = getComponentAndData(store.experiences);
            ReturnComponent(experienceData.name);
        }
    }, [ReturnComponent, store]);

    if (!component) {
        return (
            <div style={{ textAlign: 'center', paddingTop: '300px' }}>
                <hzn-loading tone="brand" label="loading content" size="large"></hzn-loading>
            </div>
        );
    }
    return (
        <Suspense
            fallback={
                <div style={{ textAlign: 'center', paddingTop: '300px' }}>
                    <hzn-loading tone="brand" size="large"></hzn-loading>
                </div>
            }
        >
            <HeroContext.Provider value={(store as unknown) as IExperienceV2}>{component}</HeroContext.Provider>
        </Suspense>
    );
}

export default HeroRefactor;
