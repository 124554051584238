import * as React from 'react';
import classNames from 'classnames';

interface IMDCSelectProps {
    id: string;
    label: string;
    value: string;
    noInitialValue?: boolean;
    options: { label: string; value: string }[];
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any;
}

interface IMDCSelectState {
    focused: boolean;
}

export default class MDCSelect extends React.Component<IMDCSelectProps, IMDCSelectState> {
    constructor(props: any) {
        super(props);

        this.state = {
            focused: false,
        };
    }

    public render(): React.ReactNode {
        return (
            <>
                <div className="mdc-select">
                    <select
                        className="mdc-select__native-control"
                        id={this.props.id}
                        onChange={this.props.onChange}
                        onFocus={() => this.setState({ focused: true })}
                        onBlur={() => this.setState({ focused: false })}
                        value={this.props.value}
                    >
                        {this.props.noInitialValue === true && <option disabled value="" />}
                        {this.props.options.map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>

                    <label
                        className={classNames('mdc-floating-label', {
                            'mdc-floating-label--float-above': this.state.focused || this.props.value !== '',
                        })}
                        htmlFor="credit-rating-select"
                    >
                        {this.props.label}
                    </label>

                    <div
                        className={classNames('mdc-line-ripple', {
                            'mdc-line-ripple--active': this.state.focused,
                        })}
                    />
                </div>
            </>
        );
    }
}
