export const track = (eventName: string, delay?: boolean) => {
    if (delay) {
        let trackingTriggered = false;
        const analyticsInterval = window.setInterval(() => {
            if ((window as any)._satellite) {
                clearInterval(analyticsInterval);
                if (!trackingTriggered) {
                    trackingTriggered = true;
                    (window as any)._satellite.track(eventName);
                }
            }
        }, 1);
    } else {
        if ((window as any)._satellite) {
            (window as any)._satellite.track(eventName);
        }
    }
};
