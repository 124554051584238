import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

interface RevealAnimationConfig {
    triggerEl: string;
    headingEl: string;
    contentEl: string;
    refreshPriority: number;
    offsetFromTopOfEl: number;
}

export const revealAnimation = (config: RevealAnimationConfig) => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(config.headingEl, { y: 70, opacity: 0 });
    gsap.set(config.contentEl, { y: 70, opacity: 0 });

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: `${config.triggerEl}`,
            start: `top-=${config.offsetFromTopOfEl} center`,
            toggleActions: 'play none none reverse',
            refreshPriority: config.refreshPriority,
            invalidateOnRefresh: true
        }
    });

    tl.to(config.headingEl, {
        y: 0,
        opacity: 1,
        duration: 0.5
    });

    tl.to(config.contentEl, {
        y: 0,
        opacity: 1,
        duration: 0.5
    });
};
