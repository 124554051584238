import * as React from 'react';

export const searchIcon = (
    <svg viewBox="0 0 24 24" width="32" height="32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            className="filled-path"
            d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 10-.7.7l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0a4.5 4.5 0 11-.01-8.99A4.5 4.5 0 019.5 14z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const recommendedIcon = (
    <svg viewBox="0 0 24 24" width="32" height="32" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path className="filled-path" d="M3,11h8V3H3ZM5,5H9V9H5Z" />
        <path className="filled-path" d="M13,3v8h8V3Zm6,6H15V5h4Z" />
        <path className="filled-path" d="M3,21h8V13H3Zm2-6H9v4H5Z" />
        <polygon
            className="filled-path"
            points="18 13 16 13 16 16 13 16 13 18 16 18 16 21 18 21 18 18 21 18 21 16 18 16 18 13"
        />
    </svg>
);

export const borderedHeartIcon = (
    <svg viewBox="0 0 24 24" width="32" height="32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            className="filled-path"
            d="M16.5 3A6 6 0 0012 5a6 6 0 00-4.5-2A5.5 5.5 0 002 8.5c0 3.8 3.4 6.9 8.6 11.5l1.4 1.4 1.4-1.4c5.2-4.6 8.6-7.7 8.6-11.5A5.5 5.5 0 0016.5 3zm.1 2A3.3 3.3 0 0120 8.4c0 2.8-3.2 5.8-8 10.2-4.8-4.4-8-7.4-8-10.2A3.3 3.3 0 017.4 5a3.9 3.9 0 012.9 1.4l1.7 2 1.7-2a3.9 3.9 0 013-1.4"
        />
    </svg>
);

export const lycgHeart = (
    <svg width="53.91" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.91 40" aria-hidden="true">
        <path
            d="M7.41,8.42A27.13,27.13,0,0,0,2.81,15l2.3,1.14a24.9,24.9,0,0,1,4.17-6Z"
            transform="translate(0 0)"
            fill="#ffc000"
        />
        <path
            d="M17.19,4.61l-1-2.35a26.69,26.69,0,0,0-6.8,4.3l1.67,1.93A24.55,24.55,0,0,1,17.19,4.61Z"
            transform="translate(0 0)"
            fill="#ffc000"
        />
        <path
            d="M23,2.9a22.43,22.43,0,0,1,3.66-.33V0a27.18,27.18,0,0,0-7.95,1.31l.79,2.44A23.19,23.19,0,0,1,23,2.9Z"
            transform="translate(0 0)"
            fill="#ffc000"
        />
        <path
            d="M46.05,7.92a27.35,27.35,0,0,0-6.47-4.79L38.38,5.4A24.41,24.41,0,0,1,44,9.54L46,8Z"
            transform="translate(0 0)"
            fill="#ffc000"
        />
        <path
            d="M29.1,2.65a24.63,24.63,0,0,1,7.07,1.72l1-2.37A26.91,26.91,0,0,0,29.32.1Z"
            transform="translate(0 0)"
            fill="#ffc000"
        />
        <path
            d="M51,39.06a27,27,0,0,0-.37-25l-2,1.62A23.88,23.88,0,0,1,51,23a24.17,24.17,0,0,1-2.28,15Z"
            transform="translate(0 0)"
            fill="#053361"
        />
        <path
            d="M49.36,11.86l-1.59-2L37.33,18.1a7.46,7.46,0,0,0-5.23-2.19,7.14,7.14,0,0,0-5.23,2.64,7.12,7.12,0,0,0-5.23-2.64,7.39,7.39,0,0,0-7.37,7.39c0,3.74,2,7.61,5.93,11.5a39.4,39.4,0,0,0,6,4.77l.68.43.69-.43a39.4,39.4,0,0,0,6-4.77c3.94-3.89,5.94-7.76,5.94-11.5V23a7.22,7.22,0,0,0-.67-2.74ZM36.91,23.3c0,3-1.74,6.28-5.17,9.67a36,36,0,0,1-4.87,4A37.3,37.3,0,0,1,22,33c-3.42-3.39-5.16-6.64-5.16-9.67a4.81,4.81,0,0,1,4.8-4.82h0c1.78,0,3,1.37,4,2.58l1.19,1.35L28,21.09c1.06-1.21,2.26-2.58,4-2.58a4.82,4.82,0,0,1,4.92,4.72h0Z"
            transform="translate(0 0)"
            fill="#053361"
        />
        <path
            d="M4.17,18.33l-2.4-.91a27.66,27.66,0,0,0-1,3.08,27.09,27.09,0,0,0,2.1,18.56l2.29-1.15a24.41,24.41,0,0,1-2-16.14,2.88,2.88,0,0,1,.13-.65A22.58,22.58,0,0,1,4.17,18.33Z"
            transform="translate(0 0)"
            fill="#053361"
        />
    </svg>
);

export const coloredHeartIcon = (
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="57.1" height="64" viewBox="0 0 57.1 64">
        <path
            d="M40,50.67l-2.48-2.22c-8.81-7.83-14.62-13-14.62-19.34a9.23,9.23,0,0,1,9.4-9.23A10.31,10.31,0,0,1,40,23.39a10.31,10.31,0,0,1,7.7-3.51,9.23,9.23,0,0,1,9.4,9.23c0,6.34-5.81,11.51-14.62,19.36Z"
            transform="translate(-22.9)"
            fill="#fa8e92"
        />
        <path
            d="M59.28,18.23a6.62,6.62,0,0,1,2.93,2.92.33.33,0,0,0,.31.2.34.34,0,0,0,.31-.2,6.6,6.6,0,0,1,2.92-2.92.34.34,0,0,0,.2-.32.33.33,0,0,0-.2-.31,6.65,6.65,0,0,1-2.92-2.92.33.33,0,0,0-.31-.2.32.32,0,0,0-.31.2,6.67,6.67,0,0,1-2.93,2.92.34.34,0,0,0-.2.31A.36.36,0,0,0,59.28,18.23Z"
            transform="translate(-22.9)"
            fill="#fff"
        />
        <path
            d="M68.48,6.47a11.47,11.47,0,0,1,5,5.05.58.58,0,0,0,.54.35.61.61,0,0,0,.54-.35,11.5,11.5,0,0,1,5-5A.58.58,0,0,0,80,5.93a.61.61,0,0,0-.35-.54,11.47,11.47,0,0,1-5-5A.61.61,0,0,0,74.07,0a.58.58,0,0,0-.54.35,11.45,11.45,0,0,1-5,5,.61.61,0,0,0-.35.54A.58.58,0,0,0,68.48,6.47Z"
            transform="translate(-22.9)"
            fill="#fff"
        />
        <path
            d="M71.13,23.77a1.25,1.25,0,1,1-1.25-1.25A1.25,1.25,0,0,1,71.13,23.77Z"
            transform="translate(-22.9)"
            fill="#fff"
        />
        <ellipse cx="17.1" cy="61.7" rx="16.9" ry="2.3" fill="#053361" opacity="0.4" />
    </svg>
);

export const recentSearchesHeaderIcon = (
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path
            className="filled-path"
            d="M22.7,19.1H21.6l-.4-.4a9.16,9.16,0,0,0,2.1-5.8A8.81,8.81,0,0,0,14.6,4,8.79,8.79,0,0,0,6,12.9H2.7l5.1,5.5,5.5-5.5H8.6a6.13,6.13,0,0,1,6-6.2,6.13,6.13,0,0,1,6,6.2,6.13,6.13,0,0,1-6,6.2,6,6,0,0,1-2.4-.5l-2,2a8.31,8.31,0,0,0,4.4,1.2,8.21,8.21,0,0,0,5.6-2.2l.4.4v1.1L27.3,28l2-2Z"
        />
        <g>
            <rect id="bad44000-0b53-4c86-a131-794ad20f5488" width="32" height="32" fill="none" />
        </g>
    </svg>
);

export const placeholderTileIcon = (
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="77.01" height="63.86" viewBox="0 0 77.01 63.86">
        <g>
            <g>
                <g>
                    <g>
                        <rect width="76.96" height="58.33" fill="none" />
                        <ellipse cx="38.01" cy="61.43" rx="17.01" ry="2.43" fill="#053361" fillOpacity="0.4" />
                        <g>
                            <path
                                d="M43.58,37.64H42l-.58-.55a13.39,13.39,0,1,0-1.44,1.44l.55.57v1.63L50.79,51l3.06-3.07Zm-12.35,0a9.26,9.26,0,1,1,9.26-9.26A9.25,9.25,0,0,1,31.23,37.64Z"
                                fill="#fff"
                            />
                        </g>
                        <path
                            d="M53.71,18.12a6.2,6.2,0,0,1,2.75,2.75.33.33,0,0,0,.3.19.3.3,0,0,0,.29-.19,6.27,6.27,0,0,1,2.76-2.75.32.32,0,0,0,.19-.3.31.31,0,0,0-.19-.29,6.29,6.29,0,0,1-2.76-2.76.32.32,0,0,0-.29-.19.35.35,0,0,0-.3.19,6.22,6.22,0,0,1-2.75,2.76.31.31,0,0,0-.19.29A.32.32,0,0,0,53.71,18.12Z"
                            fill="#fff"
                        />
                        <path
                            d="M65.22,6.63a11.78,11.78,0,0,1,5.16,5.17.62.62,0,0,0,1.11,0,11.75,11.75,0,0,1,5.17-5.17.62.62,0,0,0,0-1.11A11.75,11.75,0,0,1,71.49.35a.62.62,0,0,0-1.11,0,11.78,11.78,0,0,1-5.16,5.17.61.61,0,0,0,0,1.11Z"
                            fill="#fff"
                        />
                        <path d="M65.67,23.9a1.22,1.22,0,1,1-1.21-1.22A1.21,1.21,0,0,1,65.67,23.9Z" fill="#fff" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const viewedVehiclesHeaderIcon = (
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <rect width="32" height="32" fill="none" />
        <g>
            <g>
                <g>
                    <g>
                        <path
                            className="filled-path"
                            d="M16.5,4a12,12,0,0,0-12,12H.5l5.19,5.19.09.18L11.17,16h-4a9.36,9.36,0,1,1,2.74,6.59L8,24.48A12,12,0,1,0,16.5,4Zm-1.33,6.67v6.66l5.7,3.39,1-1.61-4.66-2.78V10.67Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
