import Cookies from 'js-cookie';

export function getStoreId(): string {
    const kmxVisitorCookie = Cookies.get('KmxVisitor_0');
    if (kmxVisitorCookie) {
        const match = kmxVisitorCookie.match(/StoreId=(\d{4})/);
        if (match && match.length > 0) {
            return match[1];
        }
    }
    return '';
}
export function getVisitorId(): string {
    const kmxVisitorCookie = Cookies.get('KmxVisitor_0');
    if (kmxVisitorCookie) {
        const match = kmxVisitorCookie.match(/VisitorID=([a-z0-9\-]+)/);
        if (match && match.length > 0) {
            return match[1];
        }
    }
    return '';
}
