export function initSmoothScrollEventListener(anchorElement: string, scrolledElement: string, offset = 0) {
    const element = <HTMLElement>document.querySelector(anchorElement);
    const elementToBeScrolledTo: HTMLElement = <HTMLElement>document.querySelector(scrolledElement);
    if (element && elementToBeScrolledTo) {
        element.addEventListener(
            'click',
            e => {
                e.preventDefault();
                smoothScroll(elementToBeScrolledTo, offset);
                elementToBeScrolledTo.focus();
                history.pushState({}, '', location.pathname + scrolledElement);
            },
            false
        );
    }
}

export function smoothScroll(scrolledElement: HTMLElement, offset = 0): any {
    const positions = calculatePositions(scrolledElement, offset);
    const keyFrames = calculateKeyFrames(positions);
    initSmoothScrolling.apply(null, keyFrames);
}

export function calculatePositions(scrolledElement: HTMLElement, offset = 0) {
    const duration = 500;
    const currentScrollPosition: number = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    const newScrollPostion: number = scrolledElement.getBoundingClientRect().top + offset;

    return {
        duration: duration,
        currentScrollPosition: currentScrollPosition,
        newScrollPostion: newScrollPostion,
    };
}

export function calculateKeyFrames({ ...positions }: any) {
    const frames: Array<number> = [];
    const frameCount: number = Math.ceil((positions.duration / 1000) * 30);
    const distance: number = positions.newScrollPostion;

    for (let i = 0; i <= frameCount; i++) {
        const t: number = i / frameCount;
        const ease: number = t < 0.5 ? 2 * t * t : -1 + 2 * (2 - t) * t;
        frames.push(positions.currentScrollPosition + distance * ease);
    }
    return frames;
}

export function initSmoothScrolling(...keyFrames: any) {
    const animation = setInterval(() => {
        if (keyFrames.length) {
            document.documentElement.scrollTop = document.body.scrollTop = keyFrames.shift() || 0;
        } else {
            clearInterval(animation);
        }
    }, 1000 / 30);
}
