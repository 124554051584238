import { FC } from 'react';

interface IMiniHomebaseSlideCTAProps {
    headline: string;
    hasFbsData?: boolean;
}

export const MiniHomebaseSlideCTA: FC<IMiniHomebaseSlideCTAProps> = ({ headline, hasFbsData }) => {
    return (
        <div className="carousel__slide" key="pusher-slide">
            <div className="carousel-slide-item">
                <div className={`mini-homebase-slide-pusher ${hasFbsData && 'has-fbs'}`}>
                    <div className="mini-homebase-slide-pusher--flex-container">
                        <h2 className="mini-homebase-slide-pusher--heading">{headline}</h2>
                        <a id="mini-homebase-cta" href="/cars/all" className="mini-homebase-slide-pusher--cta">
                            Start a Search
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
