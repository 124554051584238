import { ITaxesAndFees } from '../../../../api/taxes-and-fees-api';
import { IFinanceCalculator, IFinanceCalculatorInitialValues } from '../FinanceCalculator';
import { IFinanceCalculatorLocalStorage } from './types';
import * as constants from './constants';
import { extractNumberFromString } from '../../../../utilities/formatter';
import * as budgetCalculatorUtils from './budgetCalculatorUtils';

export interface IFees {
    totalFees: number;
    plateTransfer: string[];
    salesTax: string[];
    registration: string;
    title: string;
    additionalFees: string;
}

export const defaultFees: IFees = {
    totalFees: 0,
    plateTransfer: [],
    salesTax: [],
    registration: '',
    title: '',
    additionalFees: ''
};

export interface ITotalCost {
    vehiclePrice: number;
    totalFees: number;
}

export const buildFinanceCalculatorDefaults = (name: string, apr: string): IFinanceCalculatorInitialValues => {
    return {
        name: name,
        nearestStoreState: '',
        monthlyPayment: 400,
        downPayment: 2500,
        annualInterestRate: +apr,
        loanTerm: 72,
        taxesAndFeesList: []
    };
};

export const mapCachedFinanceCalculatorToInitialValues = (
    financeCalculatorLocalStorage: IFinanceCalculatorLocalStorage,
    financeCalculatorInitialValues: IFinanceCalculatorInitialValues,
    creditScores: budgetCalculatorUtils.ICreditScores
): IFinanceCalculatorInitialValues => {
    financeCalculatorInitialValues.nearestStoreState = financeCalculatorLocalStorage.feeState;

    if (typeof financeCalculatorLocalStorage.downPayment === 'string') {
        financeCalculatorInitialValues.downPayment = Number(
            extractNumberFromString(financeCalculatorLocalStorage.downPayment)
        );
    } else {
        financeCalculatorInitialValues.downPayment = Number(financeCalculatorLocalStorage.downPayment);
    }

    financeCalculatorInitialValues.annualInterestRate = Number(
        creditScores.allIds[
            Number(financeCalculatorLocalStorage.apr) < 0 ? 2 : Number(financeCalculatorLocalStorage.apr)
        ]
    );

    (financeCalculatorInitialValues.taxesAndFeesList as ITaxesAndFees[]) = financeCalculatorLocalStorage.feesList.sort(
        (a, b) => a.minimum - b.minimum
    );

    if (typeof financeCalculatorLocalStorage.monthlyPayment === 'number') {
        financeCalculatorInitialValues.monthlyPayment = financeCalculatorLocalStorage.monthlyPayment;
    }

    financeCalculatorInitialValues.loanTerm = Number(financeCalculatorLocalStorage.term);

    return financeCalculatorInitialValues;
};

export const calculateVehiclePrice = (financeCalculator: IFinanceCalculator): number => {
    return calculateBudget(financeCalculator) - financeCalculator.totalFees;
};

export const calculateBudget = (financeCalculator: IFinanceCalculator): number => {
    let monthlyInterestRate = null;
    const decimalMonthlyInterestRate = financeCalculator.annualInterestRate / 1200;

    if (decimalMonthlyInterestRate !== 0) {
        monthlyInterestRate =
            (1 - 1 / Math.pow(1 + decimalMonthlyInterestRate, financeCalculator.loanTerm)) / decimalMonthlyInterestRate;
    }
    return (
        financeCalculator.monthlyPayment * (monthlyInterestRate ? monthlyInterestRate : financeCalculator.loanTerm) +
        financeCalculator.downPayment
    );
};

export const calculateTotalPrice = (financeCalculator: IFinanceCalculator): number => {
    return financeCalculator.vehiclePrice + financeCalculator.totalFees;
};

export const calculateFinancePrice = (financeCalculator: IFinanceCalculator): number => {
    return financeCalculator.vehiclePrice + financeCalculator.totalFees - financeCalculator.downPayment;
};

export const getRateTier = (i: number, rateOptions: any): number => {
    return rateOptions.findIndex((r: { value: number }) => r.value == i);
};

export const getVehiclePriceAndTotalFees = (
    taxesAndFeesList: ITaxesAndFees[] | [],
    financeCalculator: IFinanceCalculator
): ITotalCost => {
    const budget = calculateBudget(financeCalculator);
    const roundedBudget = Math.round(budget);
    const totalCost: ITotalCost = {
        vehiclePrice: 0,
        totalFees: 0
    };

    if (hasEmptytaxesAndFeesList(taxesAndFeesList)) {
        totalCost.vehiclePrice = roundedBudget;
    } else if (hasLowestBudget(roundedBudget, taxesAndFeesList)) {
        totalCost.totalFees = taxesAndFeesList[0].estimatedFeesMaximum;
    } else if (hasHighestBudget(roundedBudget, taxesAndFeesList)) {
        totalCost.vehiclePrice = roundedBudget;
        totalCost.totalFees = taxesAndFeesList[taxesAndFeesList.length - 1].estimatedFeesMaximum;
    } else {
        taxesAndFeesList.forEach((taxesAndFees, i) => {
            if (budgetRangeExists(i, roundedBudget, taxesAndFeesList)) {
                totalCost.totalFees = taxesAndFees.estimatedFeesMaximum;
                totalCost.vehiclePrice = roundedBudget - totalCost.totalFees;
                return totalCost;
            } else {
                if (budgetRangeDoesNotExist(i, roundedBudget, taxesAndFeesList)) {
                    totalCost.totalFees = getHighFeesForBudgetInGap(i, taxesAndFeesList);
                    totalCost.vehiclePrice = roundedBudget - totalCost.totalFees;
                }
            }
        });
    }

    return totalCost;

    function hasEmptytaxesAndFeesList(fsl: ITaxesAndFees[]): boolean {
        return fsl.length === 0;
    }

    function hasLowestBudget(rb: number, fsl: ITaxesAndFees[]): boolean {
        return rb === 0 || rb < fsl[0].minimum + fsl[0].estimatedFeesMaximum;
    }

    function hasHighestBudget(rb: number, fsl: ITaxesAndFees[]): boolean {
        return rb > fsl[fsl.length - 1].maximum + fsl[fsl.length - 1].estimatedFeesMaximum;
    }

    function budgetRangeExists(i: number, rb: number, fsl: ITaxesAndFees[]): boolean {
        return rb >= fsl[i].minimum + fsl[i].estimatedFeesMaximum && rb <= fsl[i].maximum + fsl[i].estimatedFeesMaximum;
    }

    function budgetRangeDoesNotExist(i: number, rb: number, fsl: ITaxesAndFees[]): boolean {
        if (i === 0) {
            return false;
        } else {
            return (
                rb >= fsl[i - 1].maximum + fsl[i - 1].estimatedFeesMaximum &&
                rb <= fsl[i].minimum + fsl[i].estimatedFeesMaximum
            );
        }
    }

    function getHighFeesForBudgetInGap(i: number, fsl: ITaxesAndFees[]): number {
        return fsl[i].estimatedFeesMaximum;
    }
};
