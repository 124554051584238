import * as React from 'react';

interface INumberRollerProps {
    formatter: (arg0: number) => string;
    value: number;
    ariaLiveLabel: string;
}

interface INumberRollerState {
    formattedValue: string;
    frames: number[] | null[];
    interval: number;
    value: string;
    ariaValue: string;
}

const maxFps = 30;
const duration = 750;

export default class NumberRoller extends React.Component<INumberRollerProps, INumberRollerState> {
    constructor(props: any) {
        super(props);

        this.state = {
            formattedValue: '',
            frames: [],
            interval: 0,
            value: '',
            ariaValue: ''
        };

        this.renderAnimationFrames = this.renderAnimationFrames.bind(this);
    }

    public componentDidMount() {
        const startValue = this.props.value > 0 ? Math.floor(this.props.value / 4) * 3 : 0;
        this.setAriaValue(startValue);
        this.animate(startValue, this.props.value);
    }

    public componentDidUpdate(prevProps: INumberRollerProps) {
        if (this.props.value === prevProps.value) {
            return;
        }
        this.setAriaValue(this.props.value);
        this.animate(prevProps.value, this.props.value);
    }

    public componentWillUnmount() {
        if (this.state.interval) {
            clearInterval(this.state.interval);
        }
    }

    private setAriaValue(newValue: number) {
        this.setState({ ariaValue: this.props.formatter(newValue) });
    }

    private animate(startValue: number, endValue: number) {
        const frames = this.buildAnimationFrames(startValue, endValue);

        if (this.state.interval) {
            clearInterval(this.state.interval);
        }

        this.setState({
            frames,
            interval: window.setInterval(this.renderAnimationFrames, 1000 / maxFps)
        });
    }

    private buildAnimationFrames(startValue: number, endValue: number): number[] {
        const frames = [];
        const frameCount = Math.ceil((duration / 1000) * maxFps);
        const distance = startValue - endValue;

        for (let i = 0; i <= frameCount; i += 1) {
            const t = i / frameCount;
            const ease = t < 0.5 ? 2 * t * t : -1 + 2 * (2 - t) * t;
            frames.push(endValue + distance * ease);
        }

        return frames;
    }

    private renderAnimationFrames() {
        if (this.state.frames.length === 0) {
            clearInterval(this.state.interval);
            return;
        }

        const frames = this.state.frames;
        const value = frames.pop();
        if (typeof value === 'number') {
            const formattedValue = this.props.formatter(value);
            this.setState({
                frames,
                value: formattedValue
            });
        }
    }

    public render() {
        return (
            <>
                <div aria-live="assertive" aria-atomic={true} className="visually-hidden">
                    {this.props.ariaLiveLabel} {this.state.ariaValue}
                </div>
                <div className="calculated-value">{this.state.value}</div>
            </>
        );
    }
}
