import { SnackBar } from '../../../../components/snack-bar/SnackBar';

interface ITileKebabMenuItemSnackbarProps {
    icon?: JSX.Element;
    isOpen: boolean;
    textLabel: string;
    cancelAction: () => void;
}

const TileKebabMenuItemSnackbar = ({ isOpen, textLabel, icon, cancelAction }: ITileKebabMenuItemSnackbarProps) => {
    if (!isOpen) return null;

    return <SnackBar icon={icon} className={'tile-snackbar'} textLabel={textLabel} cancelAction={cancelAction} />;
};

export default TileKebabMenuItemSnackbar;
