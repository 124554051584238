/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { RelatedContentItem } from '../../../api/types/research-related-content-response';
import { truncateCopy } from '../../../utilities/helpers';
import Icons from '../../../utilities/icons';

interface IResearchArticlesTileProps {
    itemIndex: number;
    content: RelatedContentItem;
}

interface IResearchArticlesTileState {
    isHovered: boolean;
}

const BODY_CHARACTER_LIMIT = 220;

class ResearchArticlesTile extends React.Component<IResearchArticlesTileProps, IResearchArticlesTileState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isHovered: false,
        };
    }

    private getBodyText = (body: string, isHovered: boolean): string => {
        return isHovered ? truncateCopy(body, BODY_CHARACTER_LIMIT, true) : body;
    };

    public render(): React.ReactNode {
        this.props.content.title = this.props.content.title || '';
        return (
            <a
                className="research-articles--link"
                href={this.props.content.url}
                onMouseEnter={() => {
                    setTimeout(() => {
                        this.setState({ isHovered: true });
                    }, 75);
                }}
                onMouseLeave={() => {
                    setTimeout(() => {
                        this.setState({ isHovered: false });
                    }, 75);
                }}
                aria-label={this.props.content.title}
                data-index={this.props.itemIndex}
                data-kmx-analytics="prop5=research|prop4=aria"
            >
                <article className="research-articles--tile">
                    <div className="research-articles--tile-img">
                        <img
                            loading="lazy"
                            src={`${this.props.content.image.url}?w=270&h=230`}
                            alt={`${this.props.content.title}`}
                        />
                    </div>
                    <div className="research-articles--tile-info-container">
                        <p className="research-articles--tile-info-type">{this.props.content.category}</p>
                        <h3 className="research-articles--tile-info-title">{this.props.content.title}</h3>
                        <div
                            className="research-articles--tile-info-copy"
                            dangerouslySetInnerHTML={{
                                __html: this.getBodyText(this.props.content.body, this.state.isHovered),
                            }}
                        />
                        <span className="research-articles--tile-info-arrow">{Icons.arrowIcon}</span>
                    </div>
                </article>
            </a>
        );
    }
}

export default ResearchArticlesTile;
