import { useEffect } from 'react';
import { TransfersTile } from '../../utilities/transfersTileBuilder';
import { track } from '../../../../utilities/analytics';
import './hero-vehicle-transfer-modal.scss';

const truncate = (words: string, maxLength: number) => {
    if (words?.length > maxLength) {
        return `${words.slice(0, maxLength)}…`;
    }
    return words;
};

interface HomeBaseTransferStatusModalProps {
    transfersTile: TransfersTile;
}

export default function HomeBaseTransferStatusModal(props: HomeBaseTransferStatusModalProps) {
    const { vehicle } = props.transfersTile;
    //TODO make a function for generating title and consider null values
    const productTitle = `${vehicle.year} ${vehicle.make} ${vehicle.model} ${
        vehicle.trim === null ? '' : vehicle.trim
    }`;
    const mileage = `${Math.ceil(vehicle.mileage / 1000)}K`;

    useEffect(() => {
        track('hp-transfer-eta', true);
    }, []);

    return (
        <hzn-card
            as="aside"
            border-radius="large"
            padding="none"
            id="test__transfer-modal"
            class="transfer-status"
            aria-label="Your Car Transfer Status : Shipping"
        >
            <aside className="transfer-status__inner-wrapper" aria-label={`Your vehicle is now shipping`}>
                <div className="transfer-status__main-image-wrapper">
                    <a
                        className="transfer-status__image-link"
                        href={`/car/${vehicle.stockNumber}`}
                        title={productTitle}
                    >
                        <img
                            alt=""
                            className="transfer-status__main-image-wrapper__image"
                            src={`https://img2.carmax.com/assets/${vehicle.stockNumber}/hero.jpg?width=400&height=300`}
                        />
                    </a>
                </div>
                <div className="transfer-status__main" aria-label="Contents">
                    <a href={`/car/${vehicle.stockNumber}`} title={productTitle}>
                        <header className="transfer-status__header">
                            <hzn-badge label="Shipping" tone="positive" class="transfer-status__badge" intense>
                                <div
                                    slot="leading-icon"
                                    style={{ background: '#fff', borderRadius: '50%', height: '6px', width: '6px' }}
                                ></div>
                            </hzn-badge>
                            <h5
                                id="transfer-status__heading"
                                className="transfer-status__heading"
                                aria-label={productTitle}
                                title={productTitle}
                            >
                                {truncate(productTitle, 40)}
                            </h5>
                        </header>
                        <div className="transfer-status__details">
                            <div className="transfer-status__price" aria-label="price">
                                <span className="transfer-status__price__mark">$</span>
                                &nbsp;
                                <span className="transfer-status__price__value">
                                    {vehicle.basePrice.toLocaleString()}
                                </span>
                                <span>*</span>
                            </div>
                            <div className="transfer-status__details__divider">|</div>
                            <div className="transfer-status__details__distance" aria-label="distance">
                                <span className="transfer-status__details__distance__value">{mileage}</span>
                                &nbsp;
                                <span className="transfer-status__details__distance__unit">mi</span>
                            </div>
                        </div>
                    </a>
                    <hzn-button
                        id="test__transfer-modal__button"
                        class="transfer-status__btn"
                        href={`/activity/transfer/track?stockNumber=${vehicle.stockNumber}`}
                        variant="secondary"
                        full-width
                    >
                        track your order
                    </hzn-button>
                </div>
            </aside>
        </hzn-card>
    );
}
