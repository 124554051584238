import { Store } from '../../../types/store';

const nearestStoreLinkDataAttr = 'data-nearest-store-link';

function buildHref(store: Store): string {
    return store.Id ? `/stores/${store.Id}` : '/stores';
}

export const buildAllLinks = () => {
    const nearestStoreLinks = document.querySelectorAll(`[${nearestStoreLinkDataAttr}]`) as NodeListOf<
        HTMLElement
    > | null;

    const event = new CustomEvent('kmxSubscribeToMyStoreModuleUpdates', {
        detail: {
            done: (store: Store) => {
                if (store && nearestStoreLinks && nearestStoreLinks.length > 0) {
                    const nearestStoreHref = buildHref(store);

                    nearestStoreLinks.forEach((nearestStoreLink: HTMLElement) => {
                        nearestStoreLink.setAttribute('href', nearestStoreHref);
                        nearestStoreLink.removeAttribute(nearestStoreLinkDataAttr);
                    });
                }
            }
        }
    });
    document.documentElement.dispatchEvent(event);
};
