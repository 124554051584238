import * as React from 'react';

export const getTabPanelId = (id: string): string => `${id}-panel`;

interface ITabPanelProps {
    /**
     * Class(es) to add to the form field container
     */
    className?: string;

    /**
     * ID used for the `<input>` and other accessibility concerns
     */
    id: string;

    /**
     * Content for the button
     */
    children: React.ReactNode;

    /**
     * Whether this tab is active or not
     */
    active: boolean;
}

function TabPanel(props: ITabPanelProps) {
    const { className, children, id, active } = props;

    if (!active) {
        return null;
    }

    return (
        <div id={getTabPanelId(id)} className={className} role="tabpanel" aria-labelledby={id}>
            {children}
        </div>
    );
}

export default TabPanel;
