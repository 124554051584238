import * as React from 'react';
import InstantOffersContainer from './InstantOffersContainer';
import { loadInstantOffersComponentAssets } from './utils/loadInstantOffersComponentAssets';

interface IInstantOffersSectionProps {}

interface IInstantOffersSectionState {
    styleLoaded: boolean;
    scriptLoaded: boolean;
}

export default class InstantOffersSection extends React.Component<
    IInstantOffersSectionProps,
    IInstantOffersSectionState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            styleLoaded: false,
            scriptLoaded: false,
        };
    }

    public componentDidMount() {
        loadInstantOffersComponentAssets(
            () => {
                this.setState({
                    scriptLoaded: true,
                });
            },
            () => {
                this.setState({
                    styleLoaded: true,
                });
            }
        );
    }

    public render(): React.ReactNode {
        return this.state.scriptLoaded && this.state.styleLoaded && <InstantOffersContainer />;
    }
}
