import * as React from 'react';
import { IVehicleTile } from '../../../../types/vehicleTile';
import { ENABLE_HOMEBASE_OPTIMIZATION_EVENT } from '../../utilities/constants';
import { TransfersTile, hasInProgressTransfer } from '../../utilities/transfersTileBuilder';
import TileKebabMenuItemSnackbar from '../tile-kebab-menu/TileKebabMenuItemSnackbar';
import HomeBaseTabs from './HomeBaseTabs';
import HomeBaseTransferStatusModal from './HomeBaseTransferStatusModal';
import OptViewed from '../../homebase-optimization-test/OptViewed/ViewedVehiclesCarousel/OptViewed';

interface IHomeBaseHeroProps {
    fullStoryPageId: string;
    viewedVehicleList: IVehicleTile[];
    transfersTile: TransfersTile;
    favoritedVehicleList: IVehicleTile[];
    favoritedStockNumberList: number[];
}

interface IHomeBaseHeroState {
    dialogOpen: boolean;
    snackbarOpen: boolean;
    favoritedStockNumberList: number[];
    stockNumbersPendingFavoritesUpdate: number[];
    favoritedVehicleList: IVehicleTile[];
    snackBarText: string;
    hasHomebaseOptimization: boolean;
}

export default class HomeBaseHero extends React.Component<IHomeBaseHeroProps, IHomeBaseHeroState> {
    constructor(props: IHomeBaseHeroProps) {
        super(props);

        this.state = {
            dialogOpen: false,
            snackbarOpen: false,
            favoritedStockNumberList: [],
            stockNumbersPendingFavoritesUpdate: [],
            favoritedVehicleList: [],
            snackBarText: '',
            hasHomebaseOptimization: false,
        };

        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this);
        this.handleEnablingHomebaseOptimization = this.handleEnablingHomebaseOptimization.bind(this);
    }

    private enableHomebasePageStyles() {
        document.body.classList.add('is-return-visit');

        const headerEl = document.getElementById('header');
        if (headerEl) {
            headerEl.classList.remove('transparent-dark');
            headerEl.classList.add('transparent-light');
        }
    }

    private handleDialogOpen() {
        this.setState({
            dialogOpen: true,
        });
    }

    private handleDialogClose() {
        this.setState({
            dialogOpen: false,
        });
    }

    private handleOpenSnackbar = (text: string): void => {
        this.setState({ snackBarText: text });
        this.setState({ snackbarOpen: true });
    };

    private handleEnablingHomebaseOptimization = (event: CustomEvent<{ enabled: boolean }>) => {
        if (event?.detail && window.featureFlags.enableHomeBaseOptimization) {
            this.setState({ hasHomebaseOptimization: event.detail.enabled });
        }
    };

    public componentDidMount(): void {
        document.documentElement.addEventListener(
            ENABLE_HOMEBASE_OPTIMIZATION_EVENT,
            this.handleEnablingHomebaseOptimization as EventListener
        );
    }

    public componentWillUnmount(): void {
        document.documentElement.removeEventListener(
            ENABLE_HOMEBASE_OPTIMIZATION_EVENT,
            this.handleEnablingHomebaseOptimization as EventListener
        );
    }

    public render(): React.ReactNode {
        this.enableHomebasePageStyles();
        return (
            <div
                id="home-base-hero"
                data-fs-section={`${this.props.fullStoryPageId}: HomeBaseHero`}
                className="home-base-hero"
            >
                <picture className="home-base-hero__background-image">
                    <source media="(max-width: 474px)" srcSet="/images/home/hero/homebase-mobile.webp" />
                    <source
                        media="(min-width: 474.1px) and (max-width: 1104px)"
                        srcSet="/images/home/hero/homebase-tablet.webp"
                    />
                    <img className="home-base-hero__background-image" src="/images/home/hero/homebase-xl.webp" alt="" />
                </picture>

                {hasInProgressTransfer(this.props.transfersTile) ? (
                    <HomeBaseTransferStatusModal transfersTile={this.props.transfersTile} />
                ) : this.state.hasHomebaseOptimization ? (
                    <OptViewed />
                ) : (
                    <HomeBaseTabs
                        viewedVehicleList={this.props.viewedVehicleList}
                        favoritedVehicleList={this.state.favoritedVehicleList}
                        favoritedStockNumberList={this.state.favoritedStockNumberList}
                        onOpenSnackBar={this.handleOpenSnackbar}
                        stockNumbersPendingFavoritesUpdate={this.state.stockNumbersPendingFavoritesUpdate}
                    />
                )}

                <TileKebabMenuItemSnackbar
                    icon={undefined}
                    isOpen={this.state.snackbarOpen}
                    textLabel={this.state.snackBarText}
                    cancelAction={() => {
                        this.setState({ snackbarOpen: false });
                    }}
                />
            </div>
        );
    }
}
