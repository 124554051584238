import getFocusHandler from './getFocusHandler';
import getKeyPressHandler from './getKeyPressHandler';
import leaveSlider from './leaveSlider';
import TouchController from './TouchController';
import { AddEventHandlers } from './types';

const addEventHandlers = ({ tinySlider, nextFocus, prevFocus }: AddEventHandlers) => {
    const leaveCarousel = leaveSlider({ tinySlider, nextFocus, prevFocus });
    const keyHandler: (event: Event) => void = getKeyPressHandler({ tinySlider, leaveCarousel });
    const focusHandler: (event: Event) => void = getFocusHandler(tinySlider);
    if (!tinySlider || !tinySlider.getInfo) {
        return () => null;
    }
    const { container } = tinySlider.getInfo();
    const carousel = container.offsetParent;
    if (!carousel) {
        return () => null;
    }

    const touch = new TouchController();

    carousel.addEventListener('keydown', keyHandler);
    carousel.addEventListener('focusin', focusHandler);
    container.addEventListener('touchstart', touch.start, { passive: false });
    container.addEventListener('touchmove', touch.move, { passive: false });
    const removeEventHandlers = () => {
        carousel.removeEventListener('keydown', keyHandler);
        carousel.removeEventListener('focusin', focusHandler);
        container.removeEventListener('touchstart', touch.start);
        container.removeEventListener('touchmove', touch.move);
    };
    return removeEventHandlers;
};

export default addEventHandlers;
