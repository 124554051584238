import React from 'react';
import Icons from '../../../../utilities/icons';
import { uuid } from '../../../../utilities/uuid';
import classNames from 'classnames';

interface ITileKebabMenuProps {
    children: any;
    buttonAttrs?: { [key: string]: any };
}

const TileKebabMenu = (props: ITileKebabMenuProps) => {
    const childArray = React.Children.toArray(props.children);
    const id = uuid();
    const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
    const [isAriaExpanded, setIsAriaExpanded] = React.useState<boolean>(false);
    const menuRef = React.useRef<HTMLDivElement>(null);

    const closeMenu = React.useCallback(
        (event) => {
            if (isMenuOpen && menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
                setIsAriaExpanded(false);
            }
        },
        [isMenuOpen]
    );

    React.useEffect(() => {
        document.addEventListener('click', closeMenu);
        document.addEventListener('keydown', closeMenu);
        return () => {
            document.removeEventListener('click', closeMenu);
            document.removeEventListener('keydown', closeMenu);
        };
    }, [closeMenu]);

    const handleClick = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsAriaExpanded(!isAriaExpanded);
    };

    return (
        <div className="tile-menu" ref={menuRef}>
            <button
                className="tile-menu-button tile-menu--toggle-menu-button carousel-slide-interactive-content"
                aria-label="Toggle contextual menu"
                data-kmx-analytics="ThreeDots"
                aria-controls={`tile-menu-${id}`}
                aria-expanded={isAriaExpanded}
                {...props.buttonAttrs}
                onClick={handleClick}
            >
                {Icons.moreVert}
            </button>
            <div
                className={classNames('tile-menu--dropdown', { 'tile-menu--dropdown__open': isMenuOpen })}
                id={`tile-menu-${id}`}
                role="menu"
            >
                <ul className="tile-menu--dropdown-list">
                    {childArray.map((child: any, index: number) => {
                        return (
                            <li className="tile-menu--dropdown-list-item" key={index} tabIndex={-1}>
                                {child}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default TileKebabMenu;
