import SocialProof from './SocialProof';

interface ISocialProofContainerProps {
    fullStoryPageId: string;
    nextFocus: string;
}

function SocialProofContainer(props: ISocialProofContainerProps) {
    return (
        <>
            <SocialProof fullStoryPageId={props.fullStoryPageId} nextFocus={props.nextFocus} />
        </>
    );
}

export default SocialProofContainer;
