import * as React from 'react';

interface ILoadingSpinnerProps {
    size?: string;
    height?: string;
    className?: string;
}

interface ILoadingSpinnerState {
    atHalfway: boolean;
    dashArray: number[];
    dashOffset: number;
    speed: number;
}

export default class LoadingSpinner extends React.Component<ILoadingSpinnerProps, ILoadingSpinnerState> {
    constructor(props: ILoadingSpinnerProps) {
        super(props);

        this.state = {
            atHalfway: false,
            dashArray: [6.25, 143.75],
            dashOffset: -293.02,
            speed: 1
        };

        this.loop = this.loop.bind(this);
    }

    private animationFrameReq = 0;

    public componentDidMount() {
        this.animationFrameReq = window.requestAnimationFrame(this.loop);
    }

    public componentWillUnmount() {
        window.cancelAnimationFrame(this.animationFrameReq);
    }

    private loop() {
        const easing = 0.03;
        const atHalfway = this.state.dashOffset > -300 && this.state.dashOffset <= 0 ? true : false;

        let speed = this.state.speed;
        let dashOffset = this.state.dashOffset <= -300 ? 150 : this.state.dashOffset;
        let dashArray = this.state.dashOffset <= -300 ? [0, 150] : this.state.dashArray;

        if (!atHalfway) {
            speed += easing;
            dashOffset -= speed;
            dashArray = [(dashArray[0] += speed), (dashArray[1] -= speed)];
        } else {
            speed -= easing;
            dashOffset -= speed * 2;
            dashArray = [(dashArray[0] -= speed), (dashArray[1] += speed)];
        }

        this.setState({ dashArray, dashOffset, atHalfway, speed });

        this.animationFrameReq = window.requestAnimationFrame(this.loop);
    }

    public render() {
        const { size, height, className } = this.props;
        return (
            <div className={`${className ? className : ''}`} style={{ height: height || 'auto' }} aria-live="polite">
                <div
                    className={`js-progress-circular-indeterminate kmx-progress-circular-indeterminate kmx-progress-circular-indeterminate-${size ||
                        'medium'}`}
                    role="alert"
                    aria-busy="true"
                >
                    <span className="visually-hidden">loading</span>
                    <svg viewBox="0 0 60 60" aria-hidden="true">
                        <circle
                            cx="30"
                            cy="30"
                            r="24"
                            style={{
                                strokeDasharray: this.state.dashArray.join(','),
                                strokeDashoffset: this.state.dashOffset
                            }}
                        />
                    </svg>
                </div>
            </div>
        );
    }
}

type State = {
    dashArray: number[];
    dashOffset: number;
    atHalfway: boolean;
    speed: number;
};

type Props = {
    height?: number | string;
    size?: string;
};
