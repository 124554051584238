import * as React from 'react';
import classNames from 'classnames';
import initCarousel from '../carousel/index';
import { TinySliderInstance, TinySliderInfo } from 'tiny-slider/src/tiny-slider';

interface ICarouselProps {
    children: React.ReactElement[];
    name: string;
    nextFocus: string;
}

interface ICarouselState {
    index: number;
    slideBy: number;
    slideCount: number;
}

class Carousel extends React.Component<ICarouselProps, ICarouselState> {
    constructor(props: any) {
        super(props);

        this.state = {
            index: 0,
            slideBy: 0,
            slideCount: 0
        };

        this.handleIndexChanged = this.handleIndexChanged.bind(this);
    }

    private _tinySlider?: TinySliderInstance;
    private _removeEventHandlers?: () => void;

    public componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.children !== this.props.children && this._tinySlider) {
            this._tinySlider.refresh();
        }
    }

    public componentDidMount(): void {
        const { name, nextFocus } = this.props;
        const { tinySlider, removeEventHandlers } = initCarousel({
            nextFocus,
            container: `#${name}-carousel-slides`,
            nextButton: `#${name}-carousel__next`,
            prevButton: `#${name}-carousel__prev`,
            prevFocus: `#pre-${name}-carousel`,
            gutter: 4,
            responsive: {
                992: {
                    edgePadding: 0,
                    items: 5.5,
                    slideBy: 3
                },
                876: {
                    items: 4.5,
                    slideBy: 3
                },
                768: {
                    edgePadding: 0,
                    items: 3.8,
                    slideBy: 2
                },
                740: {
                    items: 6.2
                },
                640: {
                    items: 5.2
                },
                540: {
                    items: 4.2
                },
                410: {
                    items: 3.2
                },
                310: {
                    items: 2.2
                },
                0: {
                    edgePadding: 30,
                    items: 1.2
                }
            }
        });
        this._tinySlider = tinySlider;
        this._removeEventHandlers = removeEventHandlers;

        if (this._tinySlider) {
            this._tinySlider.events.on('indexChanged', this.handleIndexChanged);
        }
    }

    public componentWillUnmount(): void {
        if (this._tinySlider) this._tinySlider.destroy();
        if (this._removeEventHandlers) this._removeEventHandlers();
    }

    private handleIndexChanged(info: TinySliderInfo) {
        this.setState({ index: info.index, slideBy: info.slideBy, slideCount: info.slideCount });
    }

    private showPrevButton() {
        return this.state.index > 0;
    }

    private showNextButton() {
        if (!this.state.index && !this.state.slideBy && !this.state.slideCount) {
            return true;
        } else {
            return this.state.index + this.state.slideBy * 2.5 < this.state.slideCount;
        }
    }

    public render(): React.ReactNode {
        const { children, name, nextFocus } = this.props;

        const showPrevButton = this.showPrevButton(),
            showNextButton = this.showNextButton();

        return (
            <>
                <a id={`pre-${name}-carousel`} className="skip-link" href={nextFocus}>
                    Skip {name.replace(/-/g, ' ')} Carousel
                </a>
                <div
                    className={classNames('carousel max-width--xl', {
                        ['scrollable']: showPrevButton && showNextButton
                    })}
                >
                    <div id={`${name}-carousel-slides`} className="carousel__slides">
                        {React.Children.map(children, (child: React.ReactChild, index: number) => (
                            <div className="carousel__slide" key={`${index}-slide`}>
                                {child}
                            </div>
                        ))}
                        <div className="carousel__slide" key="dummy-slide" />
                    </div>
                    <div className="carousel__prev-container">
                        <div
                            className={classNames('carousel-button-bg-gradient', {
                                ['carousel-button-bg-gradient--visible']: showPrevButton
                            })}
                        />
                        <button
                            id={`${name}-carousel__prev`}
                            className={showPrevButton ? 'kmx-fab kmx-fab--secondary carousel__prev' : 'visually-hidden'}
                            tabIndex={-1}
                        >
                            <span className="visually-hidden">Previous Slide</span>
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
                                <path d="M0-.5h24v24H0z" fill="none" />
                            </svg>
                        </button>
                    </div>
                    <div className="carousel__next-container">
                        <div
                            className={classNames('carousel-button-bg-gradient', {
                                ['carousel-button-bg-gradient--visible']: showNextButton
                            })}
                        />
                        <button
                            id={`${name}-carousel__next`}
                            className={showNextButton ? 'kmx-fab kmx-fab--secondary carousel__next' : 'visually-hidden'}
                            tabIndex={-1}
                        >
                            <span className="visually-hidden">Next Slide</span>
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                                <path d="M0-.25h24v24H0z" fill="none" />
                            </svg>
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default Carousel;
