import * as React from 'react';
import classNames from 'classnames';

const RECENT_SEARCH_TILE_IMG_WIDTH = 800;
const RECENT_SEARCH_TILE_IMG_HEIGHT = 600;
const RECENT_SEARCH_TILE_PLACEHOLDER_IMG_WIDTH = 540;
const RECENT_SEARCH_TILE_PLACEHOLDER_IMG_HEIGHT = 650;

interface IRecentSearchTileImgProps {
    alt: string;
    src: string;
    inventoryCount: number;
    handleOnLoad: () => any;
}

const RecentSearchTileImg = (props: IRecentSearchTileImgProps) => {
    const { alt, src, inventoryCount, handleOnLoad } = props;

    let width = RECENT_SEARCH_TILE_IMG_WIDTH,
        height = RECENT_SEARCH_TILE_IMG_HEIGHT;

    if (props.inventoryCount === 0) {
        width = RECENT_SEARCH_TILE_PLACEHOLDER_IMG_WIDTH;
        height = RECENT_SEARCH_TILE_PLACEHOLDER_IMG_HEIGHT;
    }

    return (
        <div
            className={classNames('kmx-recent-search-tile-image-container', {
                'kmx-recent-search-tile-image-container-placeholder': inventoryCount === 0
            })}
        >
            <img
                aria-hidden="true"
                src={src}
                alt={alt}
                width={width}
                height={height}
                className={classNames('kmx-recent-search-tile-image', {
                    'kmx-recent-search-tile-image-placeholder': inventoryCount === 0
                })}
                onLoad={handleOnLoad}
            />
        </div>
    );
};

export default RecentSearchTileImg;
