import * as React from 'react';
import LazyLoad from 'react-lazyload';
import InstantOffersSection from './InstantOffersSection';

interface IInstantOffersLoaderProps {}

interface IInstantOffersLoaderState {}

export default class InstantOffersLoader extends React.Component<IInstantOffersLoaderProps, IInstantOffersLoaderState> {
    constructor(props: IInstantOffersLoaderProps) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <LazyLoad offset={[250, 250]} height={window.innerHeight}>
                <InstantOffersSection />
            </LazyLoad>
        );
    }
}
