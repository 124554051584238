export interface CardData {
    backgroundId: number; // Represents background image. e.g. cold-start-{backgroundId}.jpg
    label: string;
    href: string;
}

export const cardsData: CardData[] = [
    {
        backgroundId: 1,
        label: 'Family friendly',
        href: '/cars/third-row-seat/quad-seats/rear-view-camera?includenontransferables=true',
    },
    {
        backgroundId: 2,
        label: 'Under $20,000',
        href: '/cars?includenontransferables=true&price=20000',
    },
    {
        backgroundId: 3,
        label: 'Local trucks',
        href: '/cars/pickup-trucks?distance=50',
    },
    {
        backgroundId: 4,
        label: 'Start with a trade-in',
        href: '/sell-my-car',
    },
    {
        backgroundId: 5,
        label: 'Go electric',
        href: '/cars/electric/hybrid/plug-in-hybrid?includenontransferables=true',
    },
    {
        backgroundId: 6,
        label: 'Shop all 45,000+ cars',
        href: '/cars/all',
    },
];
