import { IServerSideExperimentContext } from '../campaigns/ServerSideExperimentContext';
import { Names } from '../HeroRefactor/Store/models';

declare global {
    interface Window {
        kmxExperiments: any;
    }
}

export const getExperimentBucket = (): IServerSideExperimentContext | null => {
    if (window.kmxExperiments) {
        const urlParams = new URLSearchParams(window.location.search);

        const preferredTestBucket = urlParams.get(Names.ColdStart);

        if (preferredTestBucket) {
            window.kmxExperiments.bucketName = preferredTestBucket;
        }

        const { bucketName, experimentName } = window.kmxExperiments;

        return { bucketName, experimentName };
    }

    return null;
};
