import * as React from 'react';
import { placeholderTileIcon } from '../../../pages/home/utilities/svg';
import { SkeletonVehicleTile } from './SkeletonVehicleTile';
import Carousel from './Carousel';

export const SearchedPlaceholderCarousel: React.FC = () => {
    return (
        <div className="home-base-hero--tab-placeholder home-base-hero--searched-tab-placeholder carousel-container">
            <ul className="tab-placeholder--list">
                <Carousel
                    name="searched-auth-tab-placeholder"
                    nextFocus="#budget-calculator-section"
                    ariaLabel="No Recent Searches"
                    leftAligned={false}
                >
                    <li
                        className="tab-placeholder--list-item carousel__slide"
                        key="searched-auth-tab-placeholder-tile-1"
                    >
                        <div className="carousel-slide-item">
                            <div className="tab-placeholder--icon">{placeholderTileIcon}</div>
                            <div className="tab-placeholder--supportive-copy">Need help getting started?</div>
                            <a href="/cars" className="tab-placeholder--cta-button">
                                <button>Start here</button>
                            </a>
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="searched-auth-tab-placeholder-tile-2"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="searched-auth-tab-placeholder-tile-3"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="searched-auth-tab-placeholder-tile-4"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                </Carousel>
            </ul>
        </div>
    );
};
