import * as React from 'react';
import classNames from 'classnames';
import FocusLock from 'react-focus-lock';
import { InstantOffersLoadingScreen } from './InstantOffersLoadingScreen';
import Icons from '../../utilities/icons';
import IneligibleOffer from './IneligibleOffer';
import {
    instantOfferEligibleEvent,
    instantOffersContainerId,
    instantOffersDialogContainerClassName,
} from './utils/constants';
import { IInstantOfferDetails } from './InstantOffers';

interface IInstantOffersFullScreenDialogProps {
    isOpen: boolean;
    isLoading: boolean;
    onClose: Function;
    isInstantOffersEligible: boolean;
    instantOfferDetails: IInstantOfferDetails;
}

function InstantOffersFullScreenDialog(props: IInstantOffersFullScreenDialogProps) {
    const scrollToTop = () => {
        const icoContainerEl = document.querySelector(`.${instantOffersDialogContainerClassName}`);
        if (icoContainerEl) {
            if ('scrollBehavior' in document.documentElement.style) {
                icoContainerEl.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
            } else {
                icoContainerEl.scrollTop = 0;
            }
        }
    };

    React.useEffect(() => {
        if (props.isOpen) {
            document.documentElement.classList.add('kmx-suyc-ico-open');
            window.addEventListener(instantOfferEligibleEvent, scrollToTop);
        } else {
            document.documentElement.classList.remove('kmx-suyc-ico-open');
            window.removeEventListener(instantOfferEligibleEvent, scrollToTop);
        }
    }, [props.isOpen]);

    const whiteFn = (node: HTMLElement) => {
        const main = document.getElementById('main');
        return main == null ? false : main.contains(node);
    };

    return (
        <FocusLock whiteList={whiteFn}>
            <div className={classNames('fullScreenDialog', { hidden: !props.isOpen })}>
                <header className="fullScreenDialog-mini-header">
                    <div className="fullScreenDialog-header-container">
                        <div className="fullScreenDialog-mini-header--logo-center fullScreenDialog-mini-header--no-action-content">
                            {Icons.carMaxLogoDark}
                        </div>
                        <div className="fullScreenDialog-mini-header--action-content">
                            <button className="closeButton dialog-close-button" onClick={() => props.onClose()}>
                                {Icons.close}
                            </button>
                        </div>
                    </div>
                </header>
                <div
                    id={instantOffersContainerId}
                    className={classNames(instantOffersDialogContainerClassName, {
                        hidden: !props.isInstantOffersEligible,
                    })}
                />
                {props.isLoading && <InstantOffersLoadingScreen />}
                {!props.isLoading && !props.isInstantOffersEligible && (
                    <div className="ico-ineligible-full-screen-container">
                        <IneligibleOffer instantOfferDetails={props.instantOfferDetails} />
                    </div>
                )}
            </div>
        </FocusLock>
    );
}

export default InstantOffersFullScreenDialog;
