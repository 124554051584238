import * as React from 'react';
import { HeroTheme } from '../../utilities/constants';
import * as analytics from '../../../../utilities/analytics';
import HeroFeaturedContent from '../HeroFeaturedContent';

interface IFirstTimeHeroProps {
    fullStoryPageId: string;
}

/*
        NOTE: Changes to this component must be duplicated in _FirstTimeHero.cshtml
*/

export default class FirstTimeHero extends React.Component<IFirstTimeHeroProps> {
    public componentDidMount(): void {
        setTimeout(function () {
            analytics.track('HP Brand Hero Init', true);
        }, 1000);
    }

    private disableHomebasePageStyles() {
        document.body.classList.remove('is-return-visit');

        const headerEl = document.getElementById('header');
        if (headerEl) {
            headerEl.classList.remove('transparent-light');
            headerEl.classList.add('transparent-dark');
        }
    }

    public render(): React.ReactNode {
        this.disableHomebasePageStyles();

        return (
            <section
                id="first-time-visitor-hero"
                data-fs-section={`${this.props.fullStoryPageId}: Hero`}
                className="first-time-visitor-hero  mdc-layout-grid"
            >
                <div className="first-time-visitor-hero--max-width-container">
                    <picture>
                        <source media="(max-width: 767px)" srcSet="/home/images/home/hero/lycg-mobile.jpg" />
                        <source
                            media="(min-width: 767.1px) and (max-width: 1151px)"
                            srcSet="/home/images/home/hero/lycg-tablet.jpg"
                        />
                        <source media="(min-width: 1151.1px)" srcSet="/home/images/home/hero/lycg-xl.jpg" />
                        <img
                            className="first-time-visitor-hero-img"
                            src="/home/images/home/hero/lycg-xl.jpg"
                            alt="Parent and child sitting in the rear of their vehicle putting on skates"
                            width="4366"
                            height="1522"
                        />
                    </picture>
                    <div className="first-time-visitor-hero--grid-container mdc-layout-grid__inner">
                        <HeroFeaturedContent heroTheme={HeroTheme.Dark} ctaLabel="Search Cars" />
                    </div>
                </div>
            </section>
        );
    }
}
