import * as React from 'react';

export const SkeletonVehicleTile: React.FC = () => {
    return (
        <div className="skeleton-vehicle-tile">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="266"
                height="323"
                viewBox="0 0 270 318"
                aria-hidden="true"
            >
                <defs>
                    <linearGradient
                        x1="-338.24"
                        y1="1.44"
                        x2="-338.24"
                        y2="2.44"
                        gradientTransform="translate(91460 -466) scale(270 325)"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#0c629a" stopOpacity="0.08" />
                        <stop offset="1" stopColor="#053361" stopOpacity="0.08" />
                    </linearGradient>
                </defs>
                <path
                    data-name="a2841865-5eac-424c-aea4-f3711d32ec34"
                    d="M0,0H270V325H0Z"
                    fillRule="evenodd"
                    fillOpacity={0}
                />
                <rect
                    x="16"
                    y="247"
                    width="216"
                    height="24"
                    rx="8"
                    fill="#053361"
                    opacity="0.16"
                    style={{ isolation: 'isolate' }}
                />
                <path
                    d="M24,215H153a8,8,0,0,0,0-16H24a8,8,0,0,0,0,16Z"
                    fill="#053361"
                    opacity="0.16"
                    style={{ isolation: 'isolate' }}
                />
                <path
                    d="M226,293H22a6,6,0,0,0,0,12H226a6,6,0,0,0,0-12Z"
                    fill="#053361"
                    opacity="0.16"
                    style={{ isolation: 'isolate' }}
                />
                <path
                    d="M24,239H153a8,8,0,0,0,0-16H24a8,8,0,0,0,0,16Z"
                    fill="#053361"
                    opacity="0.16"
                    style={{ isolation: 'isolate' }}
                />
                <rect width="270" height="181" fill="#053361" opacity="0.16" style={{ isolation: 'isolate' }} />
            </svg>
        </div>
    );
};
