import { ContentResponse } from '../../../api/types/content-api'

export interface ISocialProofPost {
    horizDirection: 'rtl' | 'ltr';
    vertDirection: 'align-top' | 'align-bottom' | '';
    validPost: boolean;
}

export interface ISocialProofPosts {
    allIds: string[];
    byId: { [key: string]: ISocialProofPost };
}

export const buildSocialProofPosts = (carouselResponse: ContentResponse): ISocialProofPosts => {
    const socialProofPosts: ISocialProofPosts = {
        allIds: carouselResponse.allPosts,
        byId: buildSocialProofPostsById(carouselResponse.allPosts, carouselResponse.portraitLeft, carouselResponse.portraitRight, carouselResponse.landscapeLeft, carouselResponse.landscapeTop, carouselResponse.landscapeBottom)
    }
    return socialProofPosts;
};

export const buildSocialProofPostsById = (ids: string[], portraitAlignLeftPosts: string[], portraitAlignRightPosts: string[], landscapeLTRPosts: string[], landscapeAlignTopPosts: string[], landscapeAlignBottomPosts: string[]): ISocialProofPosts['byId'] => {
    const socialProofPostsById: ISocialProofPosts['byId'] = {};
    ids.forEach(id => {
        socialProofPostsById[id] = {
            horizDirection: landscapeLTRPosts.indexOf(id) > -1 ? 'ltr' : 'rtl',
            vertDirection:
                landscapeAlignTopPosts.indexOf(id) > -1 || portraitAlignRightPosts.indexOf(id) > -1
                    ? 'align-top'
                    : landscapeAlignBottomPosts.indexOf(id) > -1 || portraitAlignLeftPosts.indexOf(id) > -1
                        ? 'align-bottom'
                        : '',
            validPost: false,
        };
    });
    return socialProofPostsById;
};
