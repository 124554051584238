import { LocalStorage as KmxFinanceLocalStorage } from '@kmx/online-finance-helpers';
import { updateDigitalData } from './analytics';
import * as constants from './constants';
import { aprLocalStorage } from './types';
import { IFinanceCalculator } from '../FinanceCalculator';
import { ITaxesAndFees } from '../../../../api/taxes-and-fees-api';
import * as budgetCalculatorUtils from './budgetCalculatorUtils';

export const persistFinanceCalculatorAndUpdateAnalytics = (
    financeCalculator: IFinanceCalculator,
    taxesAndFeesList: ITaxesAndFees[] | null[],
    hasInteraction: boolean,
    creditScores: budgetCalculatorUtils.ICreditScores | null
): void => {
    if (taxesAndFeesList.length > 0 && creditScores) {
        KmxFinanceLocalStorage.setFinanceInfoToStore(
            String(creditScores.allIds.indexOf(String(financeCalculator.annualInterestRate))) as aprLocalStorage,
            String(financeCalculator.loanTerm),
            financeCalculator.downPayment,
            financeCalculator.nearestStoreState,
            taxesAndFeesList as ITaxesAndFees[],
            financeCalculator.monthlyPayment,
            hasInteraction
        );
        updateDigitalData(window, financeCalculator, creditScores);
    }
};

export const persistVehicleTypePreferences = (vehicleTypeList: string[]): void => {
    localStorage.setItem(constants.vehicleTypeListLocalStorage.key, JSON.stringify(vehicleTypeList));
};
