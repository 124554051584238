import Cookies from 'js-cookie';

interface IVehicleInformation {
    vin: any;
    mileage: any;
    profile: {
        year: any;
        make: any;
        model: any;
        trim: any;
        makeCode: any;
        modelCode: any;
        trimCode: any;
    };
}

export interface IEligibleOfferLocalStorage {
    offerAmount: any;
    offerCode: any;
    expirationDateUtc: any;
    expirationDisplayDate: any;
    storeId: any;
    vehicleInformation: IVehicleInformation;
}

export interface IIneligibleOfferLocalStorage {
    expirationDateUtc: any;
    expirationDisplayDate: any;
    make?: any;
    model?: any;
}

const eligibleOfferLocalStorageKey = 'suycIcoOffer';
const ineligibleOfferLocalStorageKey = 'suycIcoOfferIneligible';
const ineligibleOfferExpiration = 86400000;

export const removeEligibleOfferFromStorage = () => {
    localStorage.removeItem(eligibleOfferLocalStorageKey);
    Cookies.remove(eligibleOfferLocalStorageKey);
};

export const getEligibleOfferFromStorage = (): IEligibleOfferLocalStorage | null => {
    try {
        let serializedEligibleOffer = Cookies.get(eligibleOfferLocalStorageKey);
        if (!serializedEligibleOffer) {
            serializedEligibleOffer = localStorage.getItem(eligibleOfferLocalStorageKey) as string;
        }

        if (serializedEligibleOffer) {
            const eligibleOfferLocalStorage: IEligibleOfferLocalStorage = JSON.parse(serializedEligibleOffer);
            const dateString = eligibleOfferLocalStorage.expirationDisplayDate as string;
            const dateStringUtc = eligibleOfferLocalStorage.expirationDateUtc as string;
            const eligibleOfferDate = new Date(dateString || dateStringUtc);

            if (!eligibleOfferDate || (eligibleOfferDate && eligibleOfferDate.getTime() < new Date().getTime())) {
                removeEligibleOfferFromStorage();
            } else {
                return eligibleOfferLocalStorage;
            }
        }
        return null;
    } catch (ex) {
        console.error('Error retrieving saved eligible offer');
        return null;
    }
};

export const setEligibleOfferToStorage = (message: any): IEligibleOfferLocalStorage | null => {
    try {
        const newOffer = {
            offerAmount: message.detail.offerAmount,
            offerCode: message.detail.offerCode,
            expirationDateUtc: message.detail.expirationDateUtc,
            expirationDisplayDate: message.detail.expirationDisplayDate,
            storeId: message.detail.storeId,
            vehicleInformation: {
                vin: message.detail.vehicleInformation.vin,
                mileage: message.detail.vehicleInformation.mileage,
                profile: {
                    year: message.detail.vehicleInformation.profile.year,
                    make: message.detail.vehicleInformation.profile.make,
                    model: message.detail.vehicleInformation.profile.model,
                    trim: message.detail.vehicleInformation.profile.trim,
                    makeCode: message.detail.vehicleInformation.profile.makeCode,
                    modelCode: message.detail.vehicleInformation.profile.modelCode,
                    trimCode: message.detail.vehicleInformation.profile.trimCode,
                },
            },
            zipCode: message.detail.zipCode,
        };
        localStorage.setItem(eligibleOfferLocalStorageKey, JSON.stringify(newOffer));
        Cookies.set(eligibleOfferLocalStorageKey, JSON.stringify(newOffer));
        return newOffer;
    } catch (ex) {
        console.error('Unable to save eligible offer to local storage', ex);
        return null;
    }
};

export const removeIneligibleOfferFromStorage = () => {
    localStorage.removeItem(ineligibleOfferLocalStorageKey);
    Cookies.remove(ineligibleOfferLocalStorageKey);
};

export const getIneligibleOfferFromStorage = (): IIneligibleOfferLocalStorage | null => {
    try {
        let serializedIneligibleOffer = Cookies.get(ineligibleOfferLocalStorageKey);
        if (!serializedIneligibleOffer) {
            serializedIneligibleOffer = localStorage.getItem(ineligibleOfferLocalStorageKey) as string;
        }

        if (serializedIneligibleOffer) {
            const ineligibleOfferLocalStorage: IIneligibleOfferLocalStorage = JSON.parse(serializedIneligibleOffer);
            const dateString = ineligibleOfferLocalStorage.expirationDisplayDate as string;
            const dateStringUtc = ineligibleOfferLocalStorage.expirationDateUtc as string;
            const ineligibleOfferDate = new Date(dateString || dateStringUtc);

            if (!ineligibleOfferDate || (ineligibleOfferDate && ineligibleOfferDate.getTime() < new Date().getTime())) {
                removeIneligibleOfferFromStorage();
            } else {
                return ineligibleOfferLocalStorage;
            }
        }
        return null;
    } catch (ex) {
        console.error('Error retrieving saved ineligible offer');
        return null;
    }
};

export const setIneligibleOfferToStorage = (make?: string, model?: string): IIneligibleOfferLocalStorage | null => {
    try {
        const newOffer = {
            expirationDateUtc: new Date().getTime() + ineligibleOfferExpiration,
            expirationDisplayDate: new Date().getTime() + ineligibleOfferExpiration,
            make: make,
            model: model,
        };
        localStorage.setItem(ineligibleOfferLocalStorageKey, JSON.stringify(newOffer));
        Cookies.set(ineligibleOfferLocalStorageKey, JSON.stringify(newOffer));
        return newOffer;
    } catch (ex) {
        console.error('Unable to save ineligible offer to local storage', ex);
        return null;
    }
};
