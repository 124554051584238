import axios from 'axios';

export interface ICreditRate {
    type: string;
    minimumRate: number;
    maximumRate: number;
    medianRate: number;
    minimumFicoScore: number | null;
    maximumFicoScore: number | null;
}

export const getCreditRatesList = (): Promise<ICreditRate[] | []> => {
    try {
        return axios
            .get('/home/api/creditrate')
            .then((response: { data: ICreditRate[] }) => {
                if (response.data && response.data.length > 0) {
                    return response.data;
                }
                return [];
            })
            .catch((error: any) => {
                console.error('CreditRatesApi: Error fetching credit rates: ', error);
                return [];
            });
    } catch (e) {
        return Promise.resolve([]);
    }
};
