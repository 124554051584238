import * as React from 'react';

interface IInstantOffersLoadingScreenProps {}

export const InstantOffersLoadingScreen: React.FC<IInstantOffersLoadingScreenProps> = props => {
    return (
        <div className="kxm-ico-animated-loader-outter animated-loader-container">
            <div className="kmx-ico-animated-loader-inner inner">
                <img
                    alt="Instant offers form loading"
                    className="kmx-ico-animated-loader-image"
                    src="/home/images/home/instant-offers/animated-loader-part-1.gif"
                />
            </div>
        </div>
    );
};
