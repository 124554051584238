import React from 'react';
import classNames from 'classnames';

export interface ITileKebabMenuItem {
    href: string;
    text: string;
    icon: JSX.Element | string;
    linkAttrs?: any;
    linkClasses?: string;
}

interface ITileKebabMenuItemProps extends ITileKebabMenuItem {
    handleClick?: (e: any) => any;
}

const TileKebabMenuItem = (props: ITileKebabMenuItemProps) => {
    const linkAttrs = props.linkAttrs ? props.linkAttrs : {};

    return (
        <a
            className={classNames(
                'tile-menu--dropdown-list-item-link carousel-slide-interactive-content',
                props.linkClasses
            )}
            href={props.href}
            tabIndex={0}
            onClick={props.handleClick}
            {...linkAttrs}
        >
            <div className="tile-menu--dropdown-list-item-content">
                <div className="tile-menu--dropdown-list-item-svg-container">
                    {typeof props.icon === 'string' ? (
                        <span
                            className="tile-menu--dropdown-list-item-svg-wrapper"
                            dangerouslySetInnerHTML={{ __html: props.icon }}
                        />
                    ) : (
                        <span className="tile-menu--dropdown-list-item-svg-wrapper">{props.icon}</span>
                    )}
                </div>
                {props.text}
            </div>
        </a>
    );
};

export default TileKebabMenuItem;
