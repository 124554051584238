import EligibleOffer from './EligibleOffer';
import IneligibleOffer from './IneligibleOffer';
import InstantOffersForm from './InstantOffersForm';
import { IInstantOffersFormData } from './InstantOffersContainer';
import classNames from 'classnames';
import CoverSection from '../CoverSection/CoverSection';

export interface IInstantOfferDetails {
    onStartNewOffer: Function;
    vehicleMake?: string;
    vehicleModel?: string;
}

interface IInstantOffersProps {
    savedOffer: any;
    hasInvalidLicensePlate: boolean;
    hasSavedEligibleOffer: boolean;
    hasSavedIneligibleOffer: boolean;
    onSubmit: (event: any, formData: IInstantOffersFormData) => any;
    instantOfferDetails: IInstantOfferDetails;
}

const InstantOffers = (props: IInstantOffersProps) => {
    const instantOffersCard = props.hasSavedEligibleOffer ? (
        <EligibleOffer
            offerAmount={props.savedOffer.offerAmount}
            expirationDateUtc={props.savedOffer.expirationDateUtc}
            expirationDisplayDate={props.savedOffer.expirationDisplayDate || props.savedOffer.expirationDateUtc}
            offerCode={props.savedOffer.offerCode}
            storeId={props.savedOffer.storeId}
            instantOfferDetails={props.instantOfferDetails}
        />
    ) : props.hasSavedIneligibleOffer ? (
        <IneligibleOffer instantOfferDetails={props.instantOfferDetails} />
    ) : (
        <InstantOffersForm hasInvalidLicensePlate={props.hasInvalidLicensePlate} onSubmit={props.onSubmit} />
    );

    return (
        <CoverSection bgImageUrl="/home/images/home/instant-offers/io-xl.jpg">
            <hzn-card
                elevation="medium"
                border-radius="large"
                padding="none"
                tone={props.hasSavedEligibleOffer ? 'brand' : 'neutral'}
                class="instant-offers__form"
            >
                <div
                    id="instantOffersContentContainer"
                    className={classNames('instant-offers__form__content', {
                        ['eligible']: props.hasSavedEligibleOffer,
                    })}
                >
                    {instantOffersCard}
                </div>
            </hzn-card>
        </CoverSection>
    );
};

export default InstantOffers;
