import * as React from 'react';
import { IFinanceCalculator } from '../FinanceCalculator';
import * as formatter from '../../../../utilities/formatter';
import * as content from '../utils/content';

interface IBudgetSearchDialogProps {
    financeCalculator: IFinanceCalculator;
}

const BudgetSearchDialogContent = (props: IBudgetSearchDialogProps) => {
    const { financeCalculator } = props;

    return (
        <div className="budget-search__dialog__body">
            <p className="budget-search__dialog__heading">{content.budgetSearchDialog.header}</p>
            <p className="budget-search__dialog__list-heading">Estimate is based on:</p>
            <ul className="budget-search__dialog__list">
                <li>
                    A financed amount of {formatter.toDollars(financeCalculator.financePrice)} (which includes{' '}
                    {formatter.toDollars(financeCalculator.totalFees)} estimated {financeCalculator.nearestStoreState}{' '}
                    tax, title, and fees)
                </li>
                <li>
                    APR of {financeCalculator.annualInterestRate}% and term of {financeCalculator.loanTerm} months
                </li>
                <li>Any information you selected</li>
            </ul>
            <p className="budget-search__dialog__fine-print">
                {content.budgetSearchDialog.buildDisclaimer(financeCalculator.loanTerm)}
            </p>
        </div>
    );
};

export default BudgetSearchDialogContent;
