import * as React from 'react';
import { getExperimentBucket } from '../utilities/experiments';

export interface IServerSideExperimentContext {
    bucketName: string;
    experimentName: string;
};

interface IServerSideExperimentContextProps {
    children: React.ReactNode;
};

export const emptyServerSideExperimentContext = {
    bucketName: '',
    experimentName: ''
};

export const ServerSideExperimentContext = React.createContext<IServerSideExperimentContext>(emptyServerSideExperimentContext);

export const ServerSideExperimentProvider = (props: IServerSideExperimentContextProps) => {
    const value = getExperimentBucket() || emptyServerSideExperimentContext;
    return (
        <ServerSideExperimentContext.Provider value={value}>
            {props.children}
        </ServerSideExperimentContext.Provider>
    );
};
