import BaseCarousel from './BaseCarousel';
import { SkeletonVehicleTile } from './SkeletonVehicleTile';
import { placeholderTileIcon } from '../../../../pages/home/utilities/svg';

interface IPlaceholderCarousel {
    carouselName: string;
    cardText?: string;
    buttonText?: string;
}

export const PlaceholderCarousel = ({ carouselName, cardText, buttonText }: IPlaceholderCarousel) => {
    return (
        <div className="home-base-hero--tab-placeholder">
            <ul className="tab-placeholder--list kmx-list">
                <BaseCarousel
                    name={carouselName}
                    nextFocus="#budget-calculator-section"
                    ariaLabel="No Viewed Vehicles Available"
                >
                    <li className="tab-placeholder--list-item carousel__slide" key={`${carouselName}-tile-0`}>
                        <div className="carousel-slide-item">
                            <div className="tab-placeholder--icon">{placeholderTileIcon}</div>
                            <div className="tab-placeholder--supportive-copy kmx-typography--display-3">{cardText}</div>
                            <a href="/cars" className="tab-placeholder--cta-button kmx-button kmx-button--primary">
                                {buttonText}
                            </a>
                        </div>
                    </li>
                    {Array.from({ length: 3 }, (_, index) => (
                        <li
                            className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                            key={`${carouselName}-tile-${index + 1}`}
                        >
                            <div className="carousel-slide-item">
                                <SkeletonVehicleTile />
                            </div>
                        </li>
                    ))}
                </BaseCarousel>
            </ul>
        </div>
    );
};
