export async function getCarImages(
    stockNumber: any,
    successCallback: (response: any) => void,
    errorCallback: (error: any) => void
) {
    try {
        const url = `https://img2.carmax.com/api/subject/${stockNumber}`;
        const res = await fetch(url, { mode: 'cors' });
        const carImages = await res.json();
        successCallback(carImages);
        return carImages;
    } catch (error) {
        errorCallback(error);
    }
}
