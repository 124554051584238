import { Vehicle } from '../../../../api/types';
import { IVehicleTile } from '../../../../types/vehicleTile';

export interface IExperienceV2 {
    experiences?: (ITransfer | IHomeBase | unknown)[];
    isLoading: boolean;
    component?: string;
    experienceData?: ITransfer | IHomeBase;
}

export enum Names {
    Transfer = 'Transfer',
    HomeBase = 'HomeBase',
    FirstTime = 'FirstTime',
    ColdStart = 'ColdStart',
}

export interface ITransfer {
    name: Names.Transfer;
    vehicle: Vehicle;
    status?: string;
    viewedVehicles?: IVehicleTile[];
}

export interface IHomeBase {
    name: Names.HomeBase;
    viewedVehicles: IVehicleTile[];
    favoritedVehicleList: IVehicleTile[];
    savedStockNumbers: number[];
    favoritedStockNumberList: number[];
    status?: string;
}
