import { FBSContext } from '../../../../context';
import { IVehicleTile } from '../../../../types/vehicleTile';
import HomeBaseHero from '../../hero/home-base/HomeBaseHero';
import { TransfersTile } from '../../utilities/transfersTileBuilder';
import { useHomeStore } from '../Store';
import { IHomeBase } from '../Store/models';
import { useContext, useEffect, useState } from 'react';
import { fetchAndBuildTransfersTile } from '../../utilities/transfersTileBuilder';

function HomeBase() {
    const homeStore = useHomeStore().experienceData;
    const fbsContext = useContext(FBSContext);
    const [transfersTile, setTransferTile] = useState({});

    const { viewedVehicles, favoritedVehicleList, savedStockNumbers } = (homeStore as unknown) as IHomeBase;

    useEffect(() => {
        if (viewedVehicles?.length > 0) {
            fbsContext.setVehicleList(viewedVehicles);
        }
    }, [viewedVehicles, fbsContext]);

    useEffect(() => {
        fetchAndBuildTransfersTile().then((transfersTile) => setTransferTile(transfersTile));
    }, []);

    return (
        <HomeBaseHero
            transfersTile={transfersTile as TransfersTile}
            fullStoryPageId="HP"
            viewedVehicleList={viewedVehicles as IVehicleTile[]}
            favoritedVehicleList={favoritedVehicleList as IVehicleTile[]}
            favoritedStockNumberList={savedStockNumbers}
        />
    );
}

export default HomeBase;
