import { createContext, useContext } from 'react';
import { create } from 'zustand';

import { IExperienceV2 } from './models';

export const HeroContext = createContext<IExperienceV2>({
    experiences: [],
    isLoading: true,
    component: undefined,
});

export const useHeroContent = () => useContext(HeroContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useHomeStore = create((set: any) => ({
    experiences: [],
    isLoading: true,
    component: undefined,
    setInitialValues: ({ experiences, component, isLoading, experienceData }: IExperienceV2) =>
        set({
            isLoading,
            experiences: experiences,
            component: component,
            experienceData: experienceData,
        }),
    experienceData: null,
    fullStoryPageId: 'HP',
    //This can be expanded to also contain the Favs list at a later date
}));

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        debugHomeStore: any;
    }
}

window.debugHomeStore = useHomeStore || {};
