import * as React from 'react';

interface IPreapprovedBudgetProps {
    stationUrl: string;
}

function PreapprovedBudget(props: IPreapprovedBudgetProps) {
    return (
        <div className="preapproved-budget-container max-width--xl">
            <div className="preapproved-budget-card-container">
                <div className="preapproved-budget-card-img" role="img" aria-hidden="true" />
                <div className="preapproved-budget-card-img-label" role="img" aria-hidden="true" />
            </div>
            <div className="preapproved-budget-details">
                <h2 className="preapproved-budget-heading">
                    Want to browse for cars that match your financing&nbsp;results?
                </h2>
                <hzn-button type="button" class="preapproved-budget-primary-button" href="/cars/all">
                    Search with my budget
                </hzn-button>
                <a className="preapproved-budget-tertiary-button" href={props.stationUrl}>
                    Review my financing
                </a>
            </div>
        </div>
    );
}

export default PreapprovedBudget;
