import * as React from 'react';
import LazyLoad from 'react-lazyload';
import StatePricingDisclaimer from './StatePricingDisclaimer';

interface ISocialProofContainerProps {
    fullStoryPageId: string;
}

class SocialProofContainer extends React.Component<ISocialProofContainerProps> {
    public render(): React.ReactNode | React.ReactPortal {
        return (
            <LazyLoad offset={[400, 400]} height={1}>
                <StatePricingDisclaimer fullStoryPageId={this.props.fullStoryPageId} />
            </LazyLoad>
        );
    }
}

export default SocialProofContainer;
