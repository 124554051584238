import * as React from 'react';
import BudgetCalculator from './BudgetCalculator';
import { getCreditRatesList } from '../../../api/credit-rates-api';
import { buildCreditScores } from './utils/budgetCalculatorUtils';
import * as budgetCalculatorUtils from './utils/budgetCalculatorUtils';
import LoadingSpinner from '../../../components/loading-spinner';

interface IBudgetCalculatorContainerProps {
    fullStoryPageId: string;
    financeCalculatorName: string;
}

const BudgetCalculatorContainer = (props: IBudgetCalculatorContainerProps) => {
    const [creditRatesList, setCreditRatesList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        getCreditRatesList()
            .then(creditRatesList => {
                if (creditRatesList && creditRatesList.length > 0) {
                    setCreditRatesList(creditRatesList as any);
                } else {
                    budgetCalculatorUtils.hideBudgetSearch();
                    console.error('BudgetCalculatorContainer: Failed to fetch credit rates list: ', creditRatesList);
                }
                setIsLoading(false);
            })
            .catch((err: any) => {
                budgetCalculatorUtils.hideBudgetSearch();
                console.error('BudgetCalculatorContainer: Failed to fetch credit rates list: ', err);
                setIsLoading(false);
            });
    }, []);

    return isLoading ? (
        <LoadingSpinner className="budget-calculator-loading-spinner" />
    ) : creditRatesList && creditRatesList.length > 0 ? (
        <BudgetCalculator {...props} creditScores={buildCreditScores(creditRatesList)} />
    ) : null;
};

export default BudgetCalculatorContainer;
