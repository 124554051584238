import * as React from 'react';
import Carousel from './Carousel';
import VehicleTypeTile from './VehicleTypeTile';
import { budgetSearch } from '../../../pages/home/budget-calculator/utils/content';
import { IVehicleTypeTile } from '../../../pages/home/budget-calculator/utils/types';

interface IVehicleTypeMultiselectCarouselProps {
    vehicleTypeList: string[];
    onVehicleTypeListChange: (vehicleTypeList: string[]) => any;
}

const VehicleTypeMultiselectCarousel = (props: IVehicleTypeMultiselectCarouselProps) => {
    const handleTypeTileClick = (type: IVehicleTypeTile) => {
        const vehicleTypeList = props.vehicleTypeList;
        const indexOfType = props.vehicleTypeList.indexOf(type.pathSegment);

        if (indexOfType > -1) {
            props.vehicleTypeList.indexOf(type.pathSegment) > -1;
            vehicleTypeList.splice(indexOfType, 1);
        } else {
            vehicleTypeList.push(type.pathSegment);
        }

        props.onVehicleTypeListChange(vehicleTypeList);
    };

    return (
        <div className="vehicle-type-multiselect-carousel-container">
            <Carousel name="vehicle-type-multiselect" nextFocus="#budget-search-calculator__monthly-payment">
                {budgetSearch.vehicleTypeCarouselSelect.vehicleTypeList.map((type, index) => {
                    const typeSelected = props.vehicleTypeList.indexOf(type.pathSegment) > -1;

                    return (
                        <VehicleTypeTile
                            key={type.pathSegment}
                            selected={typeSelected}
                            vehicleType={type}
                            itemIndex={index}
                            onClick={handleTypeTileClick}
                        />
                    );
                })}
            </Carousel>
        </div>
    );
};

export default VehicleTypeMultiselectCarousel;
