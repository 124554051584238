import { getProgressionStatus } from '../../../api/customer-progression-api';
import { getCiamId } from '../../../api/myaccount-cookie-api';
import { Vehicle } from '../../../api/types';
import { ProgressionActivities } from './constants';
import { getVehiclesFromRVC } from '../../../api/rvc-api';

interface ITransfersTile {
    vehicle: Vehicle;
    status: string;
}

export type TransfersTile = ITransfersTile;

export const fetchAndBuildTransfersTile = async (): Promise<TransfersTile> => {
    const ciamId = await getCiamId();
    if (ciamId) {
        const progressionStatus = await getProgressionStatus(ciamId);
        const { status, stockNumber } = progressionStatus;
        if (status && stockNumber > 0) {
            const vehicles = await getVehiclesFromRVC({ stockNumberList: [stockNumber] });
            if (vehicles.length > 0) {
                return { vehicle: vehicles[0], status: status };
            }
        }
    }

    return { vehicle: {} as Vehicle, status: '' } as TransfersTile;
};

export const hasInProgressTransfer = (transfersTile: ITransfersTile): boolean => {
    const inProgressStatuses = [
        ProgressionActivities.TransferOrderRequestSubmitted,
        ProgressionActivities.TransferOrderAwaitingApproval,
        ProgressionActivities.TransferOrderApproved,
        ProgressionActivities.TransferOrderTendered,
    ];
    return inProgressStatuses.includes(transfersTile?.status as ProgressionActivities);
};

export const hasCheckoutCreatedTransfer = (transfersTile: ITransfersTile): boolean => {
    return transfersTile?.status === ProgressionActivities.TransferCheckoutCreated;
};
